import styled from "@emotion/styled"
import React from "react"
import { theme } from "./theme"

export const H1 = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.lg,
  lineHeight: "24px",
  marginBottom: theme.margins.md,
  fontWeight: 600,
}))

export const DisplayText = styled.div(({ theme }) => ({
  color: theme.colors.textLight,
  alignItems: "center",
}))

export const TextError = ({
  alignRight,
  children,
}: {
  alignRight?: boolean
  children: React.ReactNode
}) => (
  <div
    style={{
      color: "red",
      marginTop: theme.margins.xs,
      textAlign: alignRight ? "end" : "start",
    }}
  >
    {children}
  </div>
)

export const Field = styled.input(({ theme }) => ({
  fontFamily: theme.fonts.regular,
  color: theme.colors.black,
  display: "flex",
  width: "100%",
  backgroundColor: theme.colors.white,
  padding: theme.margins.xs,
  paddingTop: theme.margins.xs,
  borderColor: theme.colors.borderGray,
  borderStyle: "solid",
  borderWidth: 1,
}))

export const TextWrap = styled.div(({ theme }) => ({
  maxWidth: 250,
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: theme.margins.lg,
  marginBottom: theme.margins.lg,
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  color: theme.colors.textLight,
}))

export const TextSemiBold = ({
  style,
  children,
}: {
  style?: Object
  children: React.ReactNode
}) => <SemiBold style={style}>{children}</SemiBold>

const SemiBold = styled.span({
  fontWeight: "600",
})
