const transparency = {
  invisible: "rgba(0, 0, 0, 0)",
  black050: "rgba(0, 0, 0, 0.05)",
  black100: "rgba(0, 0, 0, 0.1)",
  black200: "rgba(0, 0, 0, 0.2)",
  black300: "rgba(0, 0, 0, 0.3)",
  black400: "rgba(0, 0, 0, 0.4)",
  black500: "rgba(0, 0, 0, 0.5)",
  black600: "rgba(0, 0, 0, 0.6)",
  black700: "rgba(0, 0, 0, 0.7)",
  black800: "rgba(0, 0, 0, 0.8)",
  black900: "rgba(0, 0, 0, 0.9)",
  white100: "rgba(255, 255, 255, 0.1)",
  white200: "rgba(255, 255, 255, 0.2)",
  white300: "rgba(255, 255, 255, 0.3)",
  white400: "rgba(255, 255, 255, 0.4)",
  white500: "rgba(255, 255, 255, 0.5)",
  white600: "rgba(255, 255, 255, 0.6)",
  white700: "rgba(255, 255, 255, 0.7)",
  white800: "rgba(255, 255, 255, 0.8)",
  white900: "rgba(255, 255, 255, 0.9)",
}

const colors = {
  text: "#000",
  background: "#ffffff",
  primary: "#6E47F2",
  secondary: "#B09EE4",
  white: "#ffffff",
  black: "#000000",
  lightestGray: "#fbfbfb",
  borderGray: "#e5e5e5",
  placeholderGray: "#5D5D5D",
  textLink: "#331C99",
  textLight: "#8D8D8D",
  textMedium: "#666666",
  textPrimary: "#18224D",
  brightPink: "#FF006E",
  borderGreen: "#62E29B",
  darkGreen: "#183830",
  blue: "#5A91F7",
  red: "#DF0060",
  ...transparency,
} as const

export type Color = keyof typeof colors

const fontSizes = {
  xxxs: 10,
  xxs: 11,
  xs: 13,
  sm: 14,
  md: 15,
  lg: 18,
  xl: 20,
  xxl: 24,
  xxxl: 32,
} as const

const fonts = {
  regular: "Arial",

  // TODO: Add fonts per design
  // sailecBlackItalic: "Sailec-BlackItalic",
  // sailecBlack: "Sailec-Black",
  // sailecBoldItalic: "Sailec-BoldItalic",
  // sailecBold: "Sailec-Bold",
  // sailecLightItalic: "Sailec-LightItalic",
  // sailecLight: "Sailec-Light",
  // sailecMediumItalic: "Sailec-MediumItalic",
  // sailecMedium: "Sailec-Medium",
  // sailecRegularItalic: "Sailec-Italic",
  // sailecRegular: "Sailec",
  // sailecThinItalic: "Sailec-ThinItalic",
  // sailecThin: "Sailec-Thin",
} as const

export const theme = {
  colors,
  margins: {
    xxxs: 2,
    xxs: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 48,
  },
  fontSizes,
  fonts,
  borderWidths: {
    default: 1,
  },
} as const

export type Theme = typeof theme
