import { path } from "static-path"

export const fiveTwoNineAccountPath = path(
  "/five-two-nine-accounts/:fiveTwoNineAccountSlug",
)
export const fiveTwoNineContributionPath = path(
  "/five-two-nine-accounts/:fiveTwoNineAccountSlug/contribute",
)
export const fiveTwoNineContributionSuccessPath = path(
  "/five-two-nine-accounts/:fiveTwoNineAccountSlug/success",
)
export const contributionSignUpPath = path(
  "/five-two-nine-accounts/:fiveTwoNineAccountSlug/sign-up",
)
export const contributionSignInPath = path(
  "/five-two-nine-accounts/:fiveTwoNineAccountSlug/sign-in",
)
export const contributionForgotPasswordPath = path(
  "/five-two-nine-accounts/:fiveTwoNineAccountSlug/forgot-password",
)

export const contributionPaymentMethodPath = path(
  "/five-two-nine-accounts/:fiveTwoNineAccountSlug/payment-method",
)

export const plaidOauthCallbackPath = path("/oauth/plaid/callback")
