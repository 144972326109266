import * as Sentry from "@sentry/react"
import React, { useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import {
  usePlaidLink,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
} from "react-plaid-link"

import {
  PLAID_LINK_TOKEN_KEY,
  PLAID_RETURN_URL_KEY,
  PLAID_SUCCESS_METADATA_KEY,
} from "../../util/plaidConstants"

const OAuthLink = () => {
  const navigate = useNavigate()

  // The Link token from the first Link initialization
  const linkToken = sessionStorage.getItem(PLAID_LINK_TOKEN_KEY)
  const plaidUrl = sessionStorage.getItem(PLAID_RETURN_URL_KEY)

  const onSuccess = useCallback(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      sessionStorage.setItem(
        PLAID_SUCCESS_METADATA_KEY,
        JSON.stringify({ publicToken: public_token, metadata }),
      )
      if (plaidUrl) {
        navigate(plaidUrl, { replace: true })
      }
    },
    [],
  )

  const onExit = (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata,
  ) => {
    if (error?.error_message) {
      Sentry.setContext("plaid-error", error)
      Sentry.setContext("plaid-metadata", metadata)
      Sentry.captureException(new Error(error.error_message))
      alert("Error linking bank account\nPlease contact support")
    }
  }

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit,
  }

  const { open, ready, error } = usePlaidLink(config)

  // automatically reinitialize Link
  useEffect(() => {
    if (ready) {
      open()
    }
  }, [ready, open])

  return <></>
}

export default OAuthLink
