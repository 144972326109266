import { Field, Form, FormikProvider, useFormik } from "formik"
import React from "react"
import * as yup from "yup"

import Button from "../../ui/Button"
import { InputField } from "../../ui/FormFields"
import FormHeader from "../../ui/FormHeader"
import { Spacer } from "../../ui/Layout"

type FormValues = {
  code: string
}

const validationSchema = yup.object().shape({
  code: yup.string().required("Reset code is required").length(6),
})

const ForgotPasswordCodeForm = ({
  onSuccess,
}: {
  onSuccess: (code: string) => void
}) => {
  const onSubmit = async (values: FormValues) => {
    onSuccess(values.code)
  }

  const initialValues: FormValues = {
    code: "",
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema,
  })
  const { isSubmitting, handleSubmit } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <FormHeader
            title="Enter Your Reset Code"
            description="We sent you a code to your email address. Check your
                      messages and enter it here."
          />
          <Spacer />
          <Field component={InputField} placeholder="000000" name="code" />
          <Spacer />
          <Button
            type="submit"
            className="btn-block btn-sm"
            disabled={isSubmitting}
          >
            Confirm
          </Button>
          <Spacer />
          <Spacer />
        </Form>
      </FormikProvider>
    </>
  )
}

export default ForgotPasswordCodeForm
