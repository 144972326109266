import styled from "@emotion/styled"

import React from "react"

import { Button } from "../../ui/Button"
import ExampleCheck from "./example_check.jpg"
import CheckWidgetLeft from "./check_widget_left.svg"
import CheckWidgetRight from "./check_widget_right.svg"

export const AccountNumberHelpScreen = ({ onBack }: { onBack: () => void }) => {
  return (
    <Modal>
      <PageContainer>
        <Title>How to find your routing and account numbers from a check</Title>
        <img src={ExampleCheck} alt="Example Check" />
        <Label>Routing Number</Label>
        <Box>
          <img src={CheckWidgetLeft} alt="Check Widget Left" />
          <Text> 123456789 </Text>
          <img src={CheckWidgetRight} alt="Check Widget Right" />
        </Box>
        <TextWrapper>
          <Text>
            The ABA/routing number is nine digets long, surrounded by "
          </Text>
          <img src={CheckWidgetLeft} alt="Check Widget Left" />
          <Text>". It’s always the left-most number.</Text>
        </TextWrapper>
        <Label>Account Number</Label>
        <Box>
          <Text>987654321</Text>
        </Box>
        <Text>The checking account number.</Text>
        <Spacer />
        <Button onClick={() => onBack()}>Go Back</Button>
      </PageContainer>
    </Modal>
  )
}

export const Modal = styled.div(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

export const Title = styled.span(({ theme }) => ({
  color: theme.colors.textPrimary,
  fontSize: theme.fontSizes.lg,
  fontWeight: "600",
  marginBottom: theme.margins.md,
}))

const Container = styled.div(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  padding: theme.margins.lg,
}))

const Label = styled.span(({ theme }) => ({
  fontWeight: "bold",
  marginTop: theme.margins.lg,
  marginBottom: theme.margins.sm,
  color: theme.colors.textPrimary,
}))

const Box = styled.div(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: theme.colors.borderGray,
  paddingLeft: theme.margins.md,
  paddingRight: theme.margins.md,
  paddingTop: theme.margins.sm,
  paddingBottom: theme.margins.sm,
  marginBottom: theme.margins.sm,
}))

const TextWrapper = styled.span(() => ({
  flexDirection: "row",
  alignContent: "center",
  flexWrap: "wrap",
}))

const Text = styled.span(({ theme }) => ({
  color: theme.colors.textMedium,
}))

const Spacer = styled.div(({ theme }) => ({ height: theme.margins.lg }))

const PageContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 400,
  padding: theme.margins.lg,
}))
