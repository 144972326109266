import { Field, Form, FormikHelpers, FormikProvider, useFormik } from "formik"
import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import * as yup from "yup"
import { logEvent } from "../../analytics/analytics"
import { useAuthContext } from "../../authentication/AuthProvider"
import { useViewerMaybe } from "../../authentication/ViewerProvider"

import {
  CreateUserMutation,
  CreateUserMutationVariables,
} from "../../generated/graphql"
import { CREATE_USER } from "../../graphql/queries"
import Button from "../../ui/Button"
import { InputField, SelectField, SelectOption } from "../../ui/FormFields"
import { Row, RowField, Spacer } from "../../ui/Layout"
import SignedInNotice from "../../ui/SignedInNotice"
import { H1, TextWrap } from "../../ui/Typography"
import { assert } from "../../util/assert"
import { webLinks } from "../../util/environment"
import { useSafeMutation } from "../../util/useSafeMutation"
import { useStatesQuery } from "../../util/useStatesQuery"

type FormValues = {
  name: string
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  onboardingGeographicState?: SelectOption
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Email Address must be valid")
    .required("Email Address is required"),
  phone: yup
    .string()
    .test(
      "length",
      "Phone Number must be 10 digits",
      (val) => !val || val.length === 10,
    )
    .required("Phone Number is required"),
  password: yup.string().required("Password is required"),
  onboardingGeographicState: yup
    .object()
    .nullable()
    .required("State is required"),
})

const CreateAccountForm = ({
  title,
  description,
  onSuccess,
  signInPath,
}: {
  title: string
  description?: string
  onSuccess: () => void
  signInPath: string
}) => {
  const { token, setToken } = useAuthContext()
  const { reloadViewer } = useViewerMaybe()

  const [execCreateUser] = useSafeMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER)
  const { loading: loadingStates, data: stateData } = useStatesQuery()

  const states = useMemo(
    () =>
      stateData?.geographicStates?.map((s: any) => ({
        label: s.name,
        value: s.id,
      })),
    [stateData],
  )

  const onSubmit = async (
    values: FormValues,
    { setFieldError }: FormikHelpers<FormValues>,
  ) => {
    const localState = stateData?.geographicStates?.find(
      (s) => s?.id === values.onboardingGeographicState?.value,
    )
    logEvent("Create user", { geographicState: localState })

    // Simple captcha / honeypot
    if (values.name.length > 0) return

    const { data } = await execCreateUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          password: values.password,
          onboardingGeographicStateId: values.onboardingGeographicState?.value,
        },
      },
    })

    const errors = data?.createUser.errors

    if (errors && errors.length > 0) {
      errors.forEach((error) => {
        setFieldError(
          error.path.replace("user.", ""),
          error.messages.join("\n"),
        )
      })
    } else {
      assert(data?.createUser?.sessionToken)
      setToken(data.createUser.sessionToken)
      await reloadViewer()
      onSuccess()
    }
  }

  const initialValues: FormValues = {
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    onboardingGeographicState: undefined,
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema,
  })
  const { isSubmitting } = formik

  if (token) {
    return <SignedInNotice />
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Row>
            <RowField>
              <H1>{title}</H1>
              {description && <p>{description}</p>}
            </RowField>
          </Row>
          <Spacer size="md" />
          <Row>
            <RowField>
              <Field
                component={InputField}
                placeholder="First Name"
                name="firstName"
              />
            </RowField>
            <RowField>
              <Field
                component={InputField}
                placeholder="Last Name"
                name="lastName"
              />
            </RowField>
          </Row>
          <Spacer size="md" />
          <Field
            type="select"
            component={SelectField}
            placeholder="State"
            name="onboardingGeographicState"
            disabled={!states}
            isLoading={loadingStates}
            selectItems={states || []}
          />
          <Spacer size="md" />
          <Field
            component={InputField}
            placeholder="Email Address"
            name="email"
          />
          <Spacer size="md" />
          <Field
            component={InputField}
            placeholder="Phone Number"
            name="phone"
          />
          <Spacer size="md" />
          <Field
            component={InputField}
            placeholder="Create Password"
            name="password"
            type="password"
          />

          <div style={{ display: "none" }}>
            <Field component={InputField} placeholder="Name" name="name" />
          </div>

          <TextWrap>
            By continuing, I agree to Hadley's{" "}
            <a href={webLinks.privacyPolicy} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href={webLinks.termsOfUse} target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            .
          </TextWrap>
          <Button
            type="submit"
            className="btn-block btn-sm"
            disabled={isSubmitting || loadingStates}
          >
            Continue
          </Button>

          <TextWrap>
            Already a member? <Link to={signInPath}>Sign In</Link>
          </TextWrap>
        </Form>
      </FormikProvider>
    </>
  )
}

export default CreateAccountForm
