import { useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"

import React, { useState } from "react"
import { PlaidLinkOnSuccessMetadata, PlaidAccount } from "react-plaid-link"
import {
  SkyflowElements,
  InputFieldElement,
  useCollectContainer,
  useMakeSkyflowStyles,
  REGEX_MATCH_RULE,
  LENGTH_MATCH_RULE,
} from "skyflow-react-js"

import * as yup from "yup"
import { GetPlaidInstitutionQuery } from "../../generated/graphql"
import { GET_PLAID_INSTITUTION } from "../../graphql/queries"
import { Button } from "../../ui/Button"
import { InputField } from "../../ui/FormFields"
import { LoadingScreen } from "../../ui/LoadingScreen"
import { TitledParagrah } from "../../ui/TitledParagraph"
import { useSkyflow, SkyflowInsertionResult } from "../../util/useSkyflow"

import LockIcon from "./lock.svg"
import { AccountNumberHelpScreen } from "./AccountNumberHelpScreen"

type FormValues = {
  routing: string
  account: string
  confirmAccount: string
}

type LinkSuccess = {
  publicToken: string
  metadata: PlaidLinkOnSuccessMetadata
}

export const AddAccountInfoManually = ({
  linkSuccess,
  onSave,
  onCancel,
}: {
  linkSuccess: LinkSuccess
  onSave: (tokens: SkyflowInsertionResult) => void
  onCancel: () => void
}) => {
  const [showHelpScreen, setShowHelpScreen] = useState(false)
  const { data: institutionData, loading: institutionLoading } =
    useQuery<GetPlaidInstitutionQuery>(GET_PLAID_INSTITUTION, {
      variables: {
        plaidInstitutionId: linkSuccess.metadata.institution?.institution_id,
      },
    })
  const { skyflowConfig } = useSkyflow()
  const bankAccount = linkSuccess.metadata.accounts[0]

  if (institutionLoading || !skyflowConfig) {
    return <LoadingScreen message="Loading..." />
  }

  return (
    <>
      <SkyflowElements config={skyflowConfig}>
        <PageContainer>
          <Form
            onSave={onSave}
            onCancel={onCancel}
            institutionData={institutionData}
            bankAccount={bankAccount}
            onShowHelpScreen={() => setShowHelpScreen(true)}
          />
        </PageContainer>
      </SkyflowElements>
      {showHelpScreen && (
        <AccountNumberHelpScreen onBack={() => setShowHelpScreen(false)} />
      )}
    </>
  )
}

const Form = ({
  onSave,
  onCancel,
  onShowHelpScreen,
  institutionData,
  bankAccount,
}: {
  onSave: (tokens: SkyflowInsertionResult) => void
  onCancel: () => void
  onShowHelpScreen: () => void
  institutionData?: GetPlaidInstitutionQuery
  bankAccount: PlaidAccount
}) => {
  const container = useCollectContainer()
  const theme = useTheme()

  const useStyles = useMakeSkyflowStyles({
    inputStyles: {
      base: {
        fontFamily: "Arial",
        border: "1px solid #ced4da",
        borderRadius: "4px",
        color: theme.colors.textPrimary,
        fontSize: "1rem",
        padding: "0.375rem 0.75rem",
        lineHeight: "2",
        transition:
          "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        height: "auto",
      },
      complete: {},
      empty: {},
      focus: {},
      invalid: {
        color: "#f44336",
      },
      cardIcon: {
        position: "absolute",
        left: "8px",
        bottom: "calc(50% - 12px)",
      },
    },
    labelStyles: {
      base: {
        color: theme.colors.textPrimary,
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: "Arial",
      },
    },
    errorTextStyles: {
      base: {
        fontFamily: "Arial",
        color: "red",
      },
    },
  })
  const classes = useStyles()

  const validations = {
    routing: [
      {
        type: REGEX_MATCH_RULE,
        params: {
          regex: /^[0-9]+$/,
          error: "Routing number must be a number",
        },
      },
      {
        type: LENGTH_MATCH_RULE,
        params: {
          min: 9,
          error: "Routing number must be at least 9 digits long",
        },
      },
      {
        type: REGEX_MATCH_RULE,
        params: {
          regex: new RegExp(
            `^${(institutionData?.plaidInstitution?.routingNumbers || [])
              .map((num) => `(${num})`)
              .join("|")}$`,
          ),
          error: `Must be a valid ${
            institutionData?.plaidInstitution?.name || "institution"
          } routing number`,
        },
      },
    ],
    account: [
      {
        type: REGEX_MATCH_RULE,
        params: {
          regex: /^[0-9]+$/,
          error: "Account number must be a number",
        },
      },
      {
        type: LENGTH_MATCH_RULE,
        params: {
          min: 7,
          error: "Account number must be at least 7 digits long",
        },
      },
      {
        type: REGEX_MATCH_RULE,
        params: {
          regex: new RegExp(`${bankAccount.mask || ""}$`),
          error: `Account number must end with ${bankAccount.mask}`,
        },
      },
    ],
  }

  const handleCollect = () => {
    const response = container.collect()
    response
      .then((res: any) => {
        onSave(res.records[0].fields)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  return (
    <div className="skyflow-form-wrapper">
      <FormWrapper>
        <TitledParagrah
          title="Routing & Account Information"
          paragraph="Your banking institution requires that you also provide the routing and account number for this account."
        />
        <InputFieldElement
          container={container}
          table={"numbers_ach"}
          classes={classes}
          column={"routing"}
          label={"Routing Number"}
          validations={validations.routing}
        />
        <InputFieldElement
          container={container}
          table={"numbers_ach"}
          classes={classes}
          column={"account"}
          label={"Account Number"}
          validations={validations.account}
        />
        <DisclaimerSection>
          <img src={LockIcon} alt="lock" />
          <DisclaimerText>
            Your information is secure, we value your data safety.
          </DisclaimerText>
        </DisclaimerSection>
        <HelpWrapper>
          <Button
            buttonStyle="link"
            onClick={() => {
              onShowHelpScreen()
            }}
            style={{ paddingLeft: 0 }}
          >
            Unsure where to find this?
          </Button>
        </HelpWrapper>
      </FormWrapper>

      <Actions>
        <Button buttonStyle="primary" onClick={handleCollect}>
          Save
        </Button>
        <Button buttonStyle="link" onClick={onCancel}>
          Cancel
        </Button>
      </Actions>
    </div>
  )
}

const Label = styled.span(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.margins.sm,
  color: theme.colors.textPrimary,
}))

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 3,
}))

const FormWrapper = styled.div(({ theme }) => ({}))

const Actions = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  bottom: theme.margins.xl,
  alignItems: "center",
  width: "100%",
  paddingHorizontal: theme.margins.md,
  borderTopWidth: 1,
  borderColor: theme.colors.borderGray,
  paddingTop: theme.margins.lg,
}))

const CancelText = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  color: theme.colors.textLink,
}))

// const Cancel = styled.TouchableOpacity``
// Cancel.defaultProps = {
//   children: <CancelText>Cancel</CancelText>,
// }

// const Button = styled(OriginalButton)(({ theme }: any) => ({
//   width: "100%",
//   marginBottom: theme.margins.md,
// }))

const DisclaimerSection = styled.div(({ theme }) => ({
  marginTop: theme.margins.sm,
  flexDirection: "row",
  justifyContent: "center",
}))

const DisclaimerText = styled.span(({ theme }) => ({
  marginLeft: theme.margins.xs,
  fontSize: theme.fontSizes.xxs,
}))

const HelpWrapper = styled.div(() => ({
  marginTop: 50,
}))

const LinkText = styled.span(({ theme }) => ({
  color: theme.colors.textLink,
  textAlign: "center",
}))

const PageContainer = styled.div(({ theme }) => ({
  paddingTop: theme.margins.lg,
}))
