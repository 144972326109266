import React from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import {
  contributionForgotPasswordPath,
  contributionSignUpPath,
  fiveTwoNineContributionPath,
} from "../../util/paths"
import SignInForm from "../authentication/SignInForm"

const ContributionSignIn = () => {
  const navigate = useNavigate()
  const { fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccountSlug: string
  }>()

  const onSuccess = () =>
    navigate(
      fiveTwoNineContributionPath({
        fiveTwoNineAccountSlug,
      }),
    )

  return (
    <div>
      <SignInForm
        onSuccess={onSuccess}
        signUpPath={contributionSignUpPath({
          fiveTwoNineAccountSlug,
        })}
        forgotPasswordPath={contributionForgotPasswordPath({
          fiveTwoNineAccountSlug,
        })}
      />
    </div>
  )
}

export default ContributionSignIn
