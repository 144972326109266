import { Field, Form, FormikProvider, useFormik } from "formik"
import React from "react"
import * as yup from "yup"
import { logEvent } from "../../analytics/analytics"

import { SEND_PASSWORD_RESET_TOKEN } from "../../graphql/queries"
import Button from "../../ui/Button"
import { InputField } from "../../ui/FormFields"
import FormHeader from "../../ui/FormHeader"
import { Spacer } from "../../ui/Layout"
import { useSafeMutation } from "../../util/useSafeMutation"

type FormValues = {
  email: string
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email Address must be valid")
    .required("Email Address is required"),
})

const ForgotPasswordForm = ({
  onSuccess,
}: {
  onSuccess: (email: string) => void
}) => {
  const [execSendPassword] = useSafeMutation(SEND_PASSWORD_RESET_TOKEN, {})

  const onSubmit = async (values: FormValues) => {
    logEvent("Login", {
      email: values.email,
      userLogin: "User attempting to log in",
    })
    await execSendPassword({
      variables: {
        input: values,
      },
    })

    onSuccess(values.email)
  }

  const initialValues: FormValues = {
    email: "",
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema,
  })
  const { isSubmitting, handleSubmit } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <FormHeader
            title="Forgot Your Password?"
            description="We'll email you instructions for resetting your password."
          />
          <Spacer />
          <Field
            component={InputField}
            placeholder="Email Address"
            name="email"
          />
          <Spacer />
          <Button
            type="submit"
            className="btn-block btn-sm"
            disabled={isSubmitting}
          >
            Send Recovery Email
          </Button>
          <Spacer size="lg" />
        </Form>
      </FormikProvider>
    </>
  )
}

export default ForgotPasswordForm
