import styled from "@emotion/styled"
import { Field, Form, FormikHelpers, FormikProvider, useFormik } from "formik"
import React from "react"
import { Link } from "react-router-dom"
import * as yup from "yup"
import { logEvent } from "../../analytics/analytics"
import { useAuthContext } from "../../authentication/AuthProvider"
import { useViewerMaybe } from "../../authentication/ViewerProvider"

import { LoginMutation, LoginMutationVariables } from "../../generated/graphql"
import { LOGIN } from "../../graphql/queries"
import Button from "../../ui/Button"
import { InputField } from "../../ui/FormFields"
import FormHeader from "../../ui/FormHeader"
import { Spacer } from "../../ui/Layout"
import SignedInNotice from "../../ui/SignedInNotice"
import { TextWrap } from "../../ui/Typography"
import { assert } from "../../util/assert"
import { webLinks } from "../../util/environment"
import { useSafeMutation } from "../../util/useSafeMutation"

type FormValues = {
  email: string
  password: string
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email Address must be valid")
    .required("Email Address is required"),
  password: yup.string().required("Password is required"),
})

const SignInForm = ({
  onSuccess,
  signUpPath,
  forgotPasswordPath,
}: {
  onSuccess: () => void
  signUpPath: string
  forgotPasswordPath: string
}) => {
  const { setToken } = useAuthContext()
  const [execLogin] = useSafeMutation<LoginMutation, LoginMutationVariables>(
    LOGIN,
  )
  const { viewer, reloadViewer } = useViewerMaybe()

  const onSubmit = async (
    values: FormValues,
    { setFieldError }: FormikHelpers<FormValues>,
  ) => {
    logEvent("Login", {
      email: values.email,
      userLogin: "User attempting to log in",
    })
    const { data } = await execLogin({
      variables: {
        input: values,
      },
    })

    const errors = data?.login?.errors
    if (errors && errors.length > 0) {
      errors.forEach((error) => {
        setFieldError(error.path, error.fullMessages.join("\n"))
      })
    } else {
      assert(data?.login?.sessionToken)
      setToken(data.login.sessionToken)
      await reloadViewer()
      onSuccess()
    }
  }

  const initialValues: FormValues = {
    email: "",
    password: "",
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema,
  })
  const { isSubmitting } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <FormHeader title="Sign into your account" />
          <Spacer />
          <Field
            component={InputField}
            placeholder="Email Address"
            name="email"
          />
          <Spacer />
          <Field
            component={InputField}
            placeholder="Password"
            name="password"
            type="password"
          />
          <ForgotPasswordLinkContainer>
            <Link to={forgotPasswordPath} style={{ textDecoration: "none" }}>
              Forgot Password?
            </Link>
          </ForgotPasswordLinkContainer>
          <TextWrap>
            By logging in, I agree to Hadley's{" "}
            <a href={webLinks.privacyPolicy} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href={webLinks.termsOfUse} target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            .
          </TextWrap>
          <Button
            type="submit"
            className="btn-block btn-sm"
            disabled={isSubmitting}
          >
            Sign In
          </Button>

          {viewer ? (
            <SignedInNotice />
          ) : (
            <TextWrap>
              Not a member? <Link to={signUpPath}>Join Us</Link>
            </TextWrap>
          )}
        </Form>
      </FormikProvider>
    </>
  )
}

const ForgotPasswordLinkContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "end",
  marginTop: theme.margins.sm,
  fontSize: theme.fontSizes.xs,
}))

export default SignInForm
