import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import { appEnv } from "./environment"

let env = appEnv()
if (env.isSentryEnabled) {
  Sentry.init({
    environment: env.name,
    dsn: "https://9c25a61d316d4d79a84950ab0ac9994e@o1150868.ingest.sentry.io/6239941",
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}
