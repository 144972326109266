import { useQuery } from "@apollo/client"
import { useCallback, useState, useMemo, useEffect } from "react"

import Skyflow from "skyflow-js"
import { IConfig as SkyflowConfig } from "skyflow-react-js/lib/core"

import { SkyflowVaultAccessQuery } from "../generated/graphql"
import { SKYFLOW_VAULT_ACCESS } from "../graphql/queries"
import { appEnv } from "./environment"

export type SkyflowInsertionResult = {
  skyflow_id: string
  account: string
  routing: string
}

export const useSkyflow = () => {
  const [skyflowConfig, setSkyflowConfig] = useState<SkyflowConfig>()
  const query = useQuery<SkyflowVaultAccessQuery>(SKYFLOW_VAULT_ACCESS, {
    skip: true,
  })

  const getSkyflowConfig = useCallback(async () => {
    const result = await query.refetch()
    const { vaultId, vaultUrl } = result.data.skyflowVaultAccess

    return {
      vaultID: vaultId,
      vaultURL: vaultUrl,
      getBearerToken: async () => {
        const result = await query.refetch()
        return result.data.skyflowVaultAccess.accessToken
      },
      options: {
        logLevel: Skyflow.LogLevel.WARN,
        env: appEnv().skyflowEnv,
      },
    }
  }, [])

  useEffect(() => {
    getSkyflowConfig().then(setSkyflowConfig)
  }, [])

  return { skyflowConfig }
}
