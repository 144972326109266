import { GraphQLError } from "graphql"
// import * as Sentry from "@sentry/react-native"
import gql from "graphql-tag"
import { Maybe, ValidationError } from "../generated/graphql"
import { appEnv } from "../util/environment"

/**
 * @returns whether or not a validation error was shown
 */
export function displayValidationErrorAlert(
  errors: Pick<ValidationError, "fullMessages">[] | undefined,
  prefix = "Error: ",
): boolean {
  if (!errors) return false
  if (errors.length === 0) return false

  const firstMessage = errors[0]?.fullMessages[0]

  const err =
    firstMessage == null ? new Error("Error fullMessages missing") : null

  if (err) {
    // Sentry.captureEvent(err)
    if (appEnv().name === "debug") throw err
  }

  alert(`${prefix}${firstMessage}`)
  return true
}

export declare class CombinedError extends Error {
  name: string
  message: string
  graphQLErrors: (GraphQLError & {
    extensions?: Maybe<{ [key: string]: any }>
  })[]
  networkError?: Error
  response?: any
}

/**
 * @returns whether or not an error was shown
 */
export function displayErrorAlert(
  _error: readonly GraphQLError[] | CombinedError | undefined,
  contextualTitle?: string,
) {
  // Apollo returns a seemingly incorrect type for mutation errors
  // coerce to the known correct type
  // @ts-ignore
  const error: CombinedError | undefined = _error

  if (!error) return false

  const { title, message } = getErrorMessages(error)
  if (contextualTitle == null) {
    alert(message)
  } else {
    alert(`${title}${message != null ? ` – ${message}` : ""}`)
  }

  return true
}

export function getErrorMessages(error: CombinedError | Error) {
  let title: string
  let message: string | undefined

  if ("graphQLErrors" in error && error.graphQLErrors.length > 0) {
    const graphqlError = error.graphQLErrors[0]
    const fullMessages = graphqlError.extensions?.fullMessages

    title = graphqlError.message ?? "Unknown error"
    message = fullMessages != null ? fullMessages[0] : undefined

    // handle the scenario where on the api we called `deny!` but
    // didn't provide a translation. make it print out the name passed to
    // deny! so it's easier to track down.
    if (message === title) {
      message = Object.entries(graphqlError.extensions?.details)
        .flatMap(([k, v]: any) => `${k}: ${v.join(", ")}`)
        .join("\n")
    }
  } else if ("response" in error && error.response != null) {
    title = "Server error"
    message = `Status ${error.response.status ?? "unknown"}`
  } else if ("networkError" in error && error.networkError) {
    title = error.networkError.message ?? "A network error occured"
  } else {
    title = "An unknown error occured"
    message = error.message
  }
  return { title, message }
}

/**
 * ```graphql
 *   errors {
 *     ...ValidationErrorAlert
 *   }
 * ```
 */
export const validationErrorAlertFragment = gql`
  fragment ValidationErrorAlert on ValidationError {
    fullMessages
  }
`
