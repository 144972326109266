import React from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { fiveTwoNineContributionPath } from "../../util/paths"
import PaymentMethod from "../paymentMethod/PaymentMethod"

const ContributionPaymentMethod = () => {
  const navigate = useNavigate()
  const { fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccountSlug: string
  }>()

  return (
    <PaymentMethod
      onValidBankAccount={() => {
        navigate(
          fiveTwoNineContributionPath({
            fiveTwoNineAccountSlug,
          }),
          { replace: true },
        )
      }}
    />
  )
}

export default ContributionPaymentMethod
