import { ThemeProvider } from "@emotion/react"

import * as React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import { initializeAnalytics } from "./analytics/analytics"
import { AppApolloProvider } from "./authentication/AppApolloProvider"
import { AuthProvider } from "./authentication/AuthProvider"
import { theme } from "./ui/theme"
import * as paths from "./util/paths"

import { ViewerProvider } from "./authentication/ViewerProvider"
import Contribution from "./screens/fiveTwoNineAccounts/Contribution"
import ContributionForgotPassword from "./screens/fiveTwoNineAccounts/ContributionForgotPassword"
import ContributionPaymentMethod from "./screens/fiveTwoNineAccounts/ContributionPaymentMethod"
import ContributionSignIn from "./screens/fiveTwoNineAccounts/ContributionSignIn"
import ContributionSignUp from "./screens/fiveTwoNineAccounts/ContributionSignUp"
import ContributionSuccess from "./screens/fiveTwoNineAccounts/ContributionSuccess"
import DisplayFiveTwoNineAccount from "./screens/fiveTwoNineAccounts/DisplayFiveTwoNineAccount"
import FiveTwoNineAccount from "./screens/fiveTwoNineAccounts/FiveTwoNineAccount"
import FiveTwoNineAccountErrorBoundary from "./screens/fiveTwoNineAccounts/FiveTwoNineAccountErrorBoundary"
import PlaidReinitialize from "./screens/oauth/PlaidReinitialize"

initializeAnalytics()

const router = createBrowserRouter([
  {
    path: paths.fiveTwoNineAccountPath.pattern,
    element: <FiveTwoNineAccount />,
    errorElement: <FiveTwoNineAccountErrorBoundary />,
    children: [
      {
        index: true,
        element: <DisplayFiveTwoNineAccount />,
      },
      {
        path: paths.fiveTwoNineContributionPath.pattern,
        element: <Contribution />,
      },
      {
        path: paths.fiveTwoNineContributionSuccessPath.pattern,
        element: <ContributionSuccess />,
      },
      {
        path: paths.contributionSignUpPath.pattern,
        element: <ContributionSignUp />,
      },
      {
        path: paths.contributionSignInPath.pattern,
        element: <ContributionSignIn />,
      },
      {
        path: paths.contributionForgotPasswordPath.pattern,
        element: <ContributionForgotPassword />,
      },
      {
        path: paths.contributionPaymentMethodPath.pattern,
        element: <ContributionPaymentMethod />,
      },
    ],
  },
  {
    path: paths.plaidOauthCallbackPath.pattern,
    element: <PlaidReinitialize />,
  },
])

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("app")
  if (element) {
    ReactDOM.createRoot(element).render(
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AppApolloProvider>
            <ViewerProvider>
              <RouterProvider router={router} />
            </ViewerProvider>
          </AppApolloProvider>
        </AuthProvider>
      </ThemeProvider>,
    )
  }
})
