import React from "react"
import { useViewerMaybe } from "../authentication/ViewerProvider"
import { useLogout } from "../util/useLogout"
import Button from "./Button"
import { TextWrap } from "./Typography"

const SignedInNotice = () => {
  const { viewer } = useViewerMaybe()
  const logOut = useLogout()
  if (!viewer) return null

  return (
    <TextWrap>
      You are signed in as
      <div>{viewer.email}</div>
      <Button buttonStyle="link" className="btn-sm" onClick={logOut}>
        Sign Out
      </Button>
    </TextWrap>
  )
}

export default SignedInNotice
