import styled from "@emotion/styled"
import { Field, Formik } from "formik"
import React, { useRef, useMemo, useCallback } from "react"
import * as yup from "yup"

import { Button as OriginalButton } from "../../ui/Button"
import { InputField, SelectField } from "../../ui/FormFields"
import { Row, RowField } from "../../ui/Layout"
import { LoadingScreen } from "../../ui/LoadingScreen"
import { SectionHeading, SectionHeadingText } from "../../ui/SectionHeading"
import { useStatesQuery } from "../../util/useStatesQuery"

interface Viewer {
  billingFirstName?: string | null
  billingLastName?: string | null
  address?: {
    city?: string | null
    streetAddress1?: string | null
    streetAddress2?: string | null
    state?: {
      name?: string | null
      id: string | null
    } | null
    zip?: string | null
  } | null
}

const validationSchema = yup.object().shape({
  billingFirstName: yup.string().required("First name is required"),
  billingLastName: yup.string().required("Last name is required"),
  address: yup.string().required("Street Address is required"),
  city: yup.string().required("City is required"),
  state: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .required("State is required"),
  zip: yup.string().required("Zip is required"),
})

export const AddBillingAddress = ({
  onClose,
  viewer,
}: {
  onClose: (viewer?: Viewer | null) => void
  viewer?: Viewer | null
}) => {
  const { loading: loadingStates, data: stateData } = useStatesQuery()
  const states = useMemo(
    () =>
      stateData?.geographicStates?.map((s: any) => ({
        label: s.name,
        value: s.id,
      })),
    [stateData],
  )

  const getStateForId = useCallback(
    (id?: string | null) => {
      if (!id) {
        return null
      }
      return stateData?.geographicStates?.find((s) => s?.id === id)
    },
    [stateData],
  )

  const localState = getStateForId(viewer?.address?.state?.id)

  const initialValues = {
    billingFirstName: viewer?.billingFirstName ?? "",
    billingLastName: viewer?.billingLastName ?? "",
    address: viewer?.address?.streetAddress1 ?? "",
    address2: viewer?.address?.streetAddress2 ?? "",
    city: viewer?.address?.city ?? "",
    state: {
      label: localState?.name,
      value: viewer?.address?.state?.id,
    },
    zip: viewer?.address?.zip ?? "",
  }

  if (loadingStates) {
    return <LoadingScreen message="Loading..." />
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({
        billingFirstName,
        billingLastName,
        address,
        address2,
        city,
        state,
        zip,
      }) => {
        const localState = getStateForId(state?.value)

        onClose({
          billingFirstName,
          billingLastName,
          address: {
            streetAddress1: address,
            streetAddress2: address2,
            city,
            state: localState && {
              name: localState.name,
              id: localState.id,
            },
            zip,
          },
        })
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, errors }) => (
        <PageContainer>
          <FormWrapper>
            <SectionHeading>
              <FormDescription>
                We need your address in order to process any contributions.
              </FormDescription>
              <SectionHeadingText>Billing Address</SectionHeadingText>
            </SectionHeading>
            <Section>
              <Field
                component={InputField}
                name="billingFirstName"
                autoCapitalize="words"
                placeholder="Billing First Name"
              />
              <Spacer />
              <Field
                component={InputField}
                name="billingLastName"
                autoCapitalize="words"
                placeholder="Billing Last Name"
              />
              <Spacer />
              <Field
                component={InputField}
                name="address"
                autoComplete="street-address"
                autoCapitalize="words"
                placeholder="Address"
              />
              <Spacer />
              <Field
                component={InputField}
                name="address2"
                autoCapitalize="words"
                placeholder="Address 2"
              />
              <Spacer />
              <Field
                component={InputField}
                name="city"
                autoCapitalize="words"
                placeholder="City"
              />
              <Spacer />
              <Row>
                <RowField>
                  <Field
                    type="select"
                    component={SelectField}
                    placeholder="Select State"
                    name="state"
                    selectItems={states}
                  />
                </RowField>
                <RowField>
                  <Field
                    component={InputField}
                    name="zip"
                    autoComplete="postal-code"
                    placeholder="Zip"
                  />
                </RowField>
              </Row>
            </Section>
          </FormWrapper>
          <Actions>
            <Button type="submit" onClick={handleSubmit}>
              Save
            </Button>
            <Cancel
              href="#"
              onClick={(e) => {
                e.preventDefault()
                onClose(viewer)
              }}
            />
          </Actions>
        </PageContainer>
      )}
    </Formik>
  )
}

const FormWrapper = styled.div``

const Actions = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.margins.md,
  backgroundColor: theme.colors.white,
  bottom: theme.margins.xl,
  alignItems: "center",
  width: "100%",
  paddingHorizontal: theme.margins.md,
}))

const CancelText = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  color: theme.colors.textLink,
}))

const Cancel = styled.a``
Cancel.defaultProps = {
  children: <CancelText>Cancel</CancelText>,
}

const Button = styled(OriginalButton)(({ theme }: any) => ({
  width: "100%",
  marginBottom: theme.margins.md,
}))

const Section = styled.div(({ theme }) => ({
  paddingTop: theme.margins.md,
  paddingBottom: theme.margins.md,
}))

const FormDescription = styled(SectionHeadingText)(({ theme }) => ({
  marginBottom: theme.margins.sm,
  color: theme.colors.black,
}))

const Spacer = styled.div(({ theme }) => ({
  height: theme.margins.md,
}))

const PageContainer = styled.div(({ theme }) => ({
  marginTop: theme.margins.lg,
}))
