import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["programs", "plans"]
  static values = {
    programsUrl: String,
    plansUrl: String,
  }

  updatePrograms(event) {
    if (event.target.value) {
      fetch(this.programsUrl(event.target.value))
        .then((resp) => resp.json())
        .then((programs) => this.setPrograms(programs))
    } else {
      this.setPrograms([])
    }
  }

  updatePlans(event) {
    if (event.target.value) {
      fetch(this.plansUrl(event.target.value))
        .then((resp) => resp.json())
        .then((plans) => this.setPlans([null, ...plans]))
    } else {
      this.setPlans([])
    }
  }

  setPrograms(programs) {
    this.programsTarget.innerHTML = ""
    for (const program of programs) {
      this.appendProgram(program)
    }
    this.updatePlans({ target: { value: programs[0]?.id } })
  }

  setPlans(plans) {
    this.plansTarget.innerHTML = ""
    for (const plan of plans) {
      this.appendPlan(plan)
    }
  }

  appendProgram(program) {
    const option = document.createElement("option")
    option.text = program?.name || ""
    option.value = program?.id || ""
    this.programsTarget.add(option)
  }

  appendPlan(plan) {
    const option = document.createElement("option")
    option.text = plan?.name || ""
    option.value = plan?.id || ""
    this.plansTarget.add(option)
  }

  programsUrl(stateId) {
    return this.programsUrlValue.replace("STATE_ID", stateId)
  }

  plansUrl(programId) {
    return this.plansUrlValue.replace("PROGRAM_ID", programId)
  }
}
