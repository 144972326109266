import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import * as yup from "yup"

import {
  ContributionVisibility,
  CreateContributionInput,
  FiveTwoNineAccount,
} from "../../../generated/graphql"
import Button from "../../../ui/Button"
import { Card, CardBody } from "../../../ui/Card"
import { InputField } from "../../../ui/FormFields"
import { Spacer } from "../../../ui/Layout"
import { TextError } from "../../../ui/TextError"
import {
  dollarAmountValidation,
  useMinimumPayment,
} from "../../../util/validations"
import { AmountInput } from "./AmountInput"
import { ContributionConfirmation } from "./ContributionConfirmation"
import { RecipientListItem } from "./RecipientListItem"

type Values = {
  amount: string
  message: string
  isPrivate: boolean
}

const MAX_MESSAGE_LENGTH = 500

const ContributionForm = ({
  fiveTwoNineAccount,
}: { fiveTwoNineAccount: FiveTwoNineAccount }) => {
  const [contribution, setContribution] = useState<
    CreateContributionInput | undefined
  >()

  const { minimum } = useMinimumPayment(fiveTwoNineAccount.id)

  const initialValues: Values = {
    // If there is a minimum, set default amount to minimum. Otherwise, default to $25
    amount: minimum ? minimum.toString() : "25",
    message: "",
    isPrivate: false,
  }

  const validationSchema = yup.object().shape({
    message: yup.string().max(MAX_MESSAGE_LENGTH),
    ...dollarAmountValidation(minimum),
  })

  const theme = useTheme()

  const onSubmit = (values: Values) => {
    setContribution({
      amountCents: Number(values.amount) * 100,
      fiveTwoNineAccountId: fiveTwoNineAccount.id,
      message: values.message,
      visibility: values.isPrivate
        ? ContributionVisibility.Private
        : ContributionVisibility.Public,
    })
  }

  if (contribution)
    return (
      <ContributionConfirmation
        contribution={contribution}
        clearContribution={() => setContribution(undefined)}
      />
    )

  return (
    <>
      <ContributionHeaderDiv>
        <CancelLink href="/">Cancel</CancelLink>
        <ContributionHeaderTitleDiv>Contribute</ContributionHeaderTitleDiv>
      </ContributionHeaderDiv>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isValid, isSubmitting, errors, touched }) => (
          <Form>
            <Card>
              <CardBody>
                <RecipientView>
                  <RecipientItemView>
                    <RecipientListItem
                      fiveTwoNineAccount={fiveTwoNineAccount}
                    />
                  </RecipientItemView>
                  <AmountInput autoFocus={true} />
                </RecipientView>
                <Field
                  component={MessageTextInput}
                  placeholder="Leave a note — sweet, silly or sassy, it's up to you."
                  name="message"
                  textarea={true}
                  maxLength={MAX_MESSAGE_LENGTH}
                  rows={5}
                />
                {!!minimum && (
                  <InfoText>
                    *Your gift recipient is in a 529 Plan that requires a
                    minimum of ${minimum} to make a contribution.
                  </InfoText>
                )}
                {errors.amount && touched.amount && (
                  <div
                    style={{
                      paddingLeft: theme.margins.md,
                      paddingRight: theme.margins.md,
                      flexDirection: "row-reverse",
                    }}
                  >
                    <TextError>{errors.amount}</TextError>
                  </div>
                )}
              </CardBody>
            </Card>
            <Spacer size="lg" />
            <ContributeButtonView>
              <Button
                className="btn-block"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Contribute
              </Button>
            </ContributeButtonView>
          </Form>
        )}
      </Formik>
    </>
  )
}

const RecipientView = styled.div(({ theme }) => ({
  marginTop: theme.margins.md,
  display: "flex",
  flexDirection: "row",
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.borderGray,
  borderBottomStyle: "solid",
  borderTopWidth: 1,
  borderTopColor: theme.colors.borderGray,
  borderTopStyle: "solid",
}))

const RecipientItemView = styled.div(() => ({
  width: 280,
}))

const MessageTextInput = styled(InputField)(({ theme }) => ({
  flex: 1,
  textAlignVertical: "top",
  fontSize: theme.fontSizes.sm,
  borderColor: theme.colors.borderGray,
  borderRadius: 4,
  marginTop: theme.margins.lg,
  padding: theme.margins.md,
  width: "100%",
  outline: 0,
}))

const ContributeButtonView = styled.div(({ theme }) => ({
  marginHorizontal: theme.margins.md,
  marginBottom: theme.margins.xxl,
}))

const InfoText = styled.div(({ theme }) => ({
  color: theme.colors.textLight,
  fontSize: theme.fontSizes.xs,
  textAlign: "center",
  marginTop: theme.margins.lg,
}))

const CancelLink = styled.a(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  color: theme.colors.textLight,
  textDecoration: "none",
  position: "absolute",
  top: 2,
  left: 0,
}))

export const ContributionHeaderDiv = styled.div(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.margins.lg,
}))

export const ContributionHeaderTitleDiv = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xl,
  fontWeight: 500,
}))

export default ContributionForm
