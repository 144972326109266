import styled from "@emotion/styled"

export const ContainerDiv = styled.div(({ theme }) => ({
  maxWidth: 440,
  margin: "0 auto",
}))

export const ContentDiv = styled.div(({ theme }) => ({
  paddingLeft: theme.margins.lg,
  paddingRight: theme.margins.lg,
  marginTop: theme.margins.xl,
  marginBottom: 50,
  fontSize: theme.fontSizes.sm,
  lineHeight: "20px",
}))

export const Spacer = styled.div<{ size?: "sm" | "md" | "lg" | "xl" }>(
  ({ theme, size }) => ({
    height: theme.margins[size || "sm"],
  }),
)

export const Row = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginLeft: -theme.margins.xs,
  marginRight: -theme.margins.xs,
}))

export const RowField = styled.div(({ theme }) => ({
  flex: 1,
  marginLeft: theme.margins.xs,
  marginRight: theme.margins.xs,
}))
