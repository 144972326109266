import React from "react"
import { Link } from "react-router-dom"

type ButtonStyle = "primary" | "secondary" | "danger" | "link"

export const Button = ({
  onClick,
  buttonStyle = "primary",
  disabled,
  href,
  className,
  type,
  children,
  style,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  buttonStyle?: ButtonStyle
  disabled?: boolean
  href?: string
  className?: string
  type?: "submit" | "button"
  style?: any
  children: React.ReactNode
}) => {
  const classes = `btn btn-rounded btn-${buttonStyle} ${className}`

  if (href) {
    return (
      <Link to={href} className={classes}>
        {children}
      </Link>
    )
  }

  return (
    <button
      onClick={onClick}
      className={classes}
      disabled={disabled}
      type={type}
      style={style}
    >
      {children}
    </button>
  )
}

export default Button
