import { Field, Form, FormikProvider, useFormik } from "formik"
import React from "react"
import * as yup from "yup"
import { useAuthContext } from "../../authentication/AuthProvider"
import {
  ResetPasswordAndLoginMutation,
  ResetPasswordAndLoginMutationVariables,
} from "../../generated/graphql"

import { RESET_PASSWORD_AND_LOGIN } from "../../graphql/queries"
import Button from "../../ui/Button"
import { InputField } from "../../ui/FormFields"
import FormHeader from "../../ui/FormHeader"
import { Spacer } from "../../ui/Layout"
import { assert } from "../../util/assert"
import { displayValidationErrorAlert } from "../../util/errorAlerts"
import { useSafeMutation } from "../../util/useSafeMutation"

type FormValues = {
  newPassword: ""
  newPasswordConfirmation: ""
}

const validationSchema = yup.object().shape({
  newPassword: yup.string().required("New password is required"),
  newPasswordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "Password confirmation does not match new password",
    )
    .required("New password confirmation is required"),
})

const UpdatePasswordForm = ({
  email,
  code,
  onSuccess,
}: {
  email: string
  code: string
  onSuccess: () => void
}) => {
  const { setToken } = useAuthContext()

  const [execResetAndLogin] = useSafeMutation<
    ResetPasswordAndLoginMutation,
    ResetPasswordAndLoginMutationVariables
  >(RESET_PASSWORD_AND_LOGIN)

  const onSubmit = async (values: FormValues) => {
    const { data } = await execResetAndLogin({
      variables: {
        input: {
          password: values.newPassword,
          email,
          resetToken: code,
        },
      },
    })

    const errors = data?.resetPasswordAndLogin?.errors

    if (displayValidationErrorAlert(errors)) {
      return
    }

    assert(data?.resetPasswordAndLogin?.sessionToken)
    setToken(data.resetPasswordAndLogin.sessionToken)
    onSuccess()
  }

  const initialValues: FormValues = {
    newPassword: "",
    newPasswordConfirmation: "",
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema,
  })
  const { isSubmitting, handleSubmit } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <FormHeader
            title="Update Password"
            description="We'll email you instructions for resetting your password."
          />
          <Spacer />
          <Field
            component={InputField}
            placeholder="New Password"
            name="newPassword"
            type="password"
          />
          <Spacer />
          <Field
            component={InputField}
            placeholder="New Password Confirmation"
            name="newPasswordConfirmation"
            type="password"
          />
          <Spacer />
          <Button
            type="submit"
            className="btn-block btn-sm"
            disabled={isSubmitting}
          >
            Continue
          </Button>
          <Spacer size="lg" />
        </Form>
      </FormikProvider>
    </>
  )
}

export default UpdatePasswordForm
