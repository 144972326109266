import React from "react"
import { theme } from "./theme"

export const TextError = ({
  alignRight,
  children,
}: {
  alignRight?: boolean
  children: React.ReactNode
}) => (
  <span
    style={{
      color: "red",
      marginTop: theme.margins.xs,
      textAlign: alignRight ? "right" : "center",
    }}
  >
    {children}
  </span>
)
