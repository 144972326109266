import styled from "@emotion/styled"
import React from "react"
import { Link, useOutletContext } from "react-router-dom"
import { useAuthContext } from "../../authentication/AuthProvider"
import { useViewerMaybe } from "../../authentication/ViewerProvider"
import { FiveTwoNineAccount } from "../../generated/graphql"

import Button from "../../ui/Button"
import { Card, CardBody } from "../../ui/Card"
import { LargeFiveTwoNineAvatar } from "../../ui/FiveTwoNineAvatar"
import { Spacer } from "../../ui/Layout"
import { H1, TextWrap } from "../../ui/Typography"
import {
  contributionSignInPath,
  contributionSignUpPath,
  fiveTwoNineContributionPath,
} from "../../util/paths"

const getNextStep = (isLoggedIn: boolean, fiveTwoNineAccountSlug: string) => {
  if (isLoggedIn) {
    return fiveTwoNineContributionPath({
      fiveTwoNineAccountSlug,
    })
  }
  return contributionSignUpPath({
    fiveTwoNineAccountSlug,
  })
}

const DisplayFiveTwoNineAccount = () => {
  const { fiveTwoNineAccount, fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccount: FiveTwoNineAccount
    fiveTwoNineAccountSlug: string
  }>()
  const { viewer } = useViewerMaybe()
  const { token } = useAuthContext()

  return (
    <div>
      <H1>
        Contribute to {fiveTwoNineAccount.beneficiaryFirstName}'s education!
      </H1>

      <p>
        {fiveTwoNineAccount?.user?.firstName} would like to thank you for giving
        the gift of education. Click contribute to make a one-time contribution.
      </p>
      <Spacer />
      <Card>
        <CardBody>
          <LargeFiveTwoNineAvatar fiveTwoNineAccount={fiveTwoNineAccount} />
          <FirstNameDiv>{fiveTwoNineAccount.beneficiaryFirstName}</FirstNameDiv>
        </CardBody>
      </Card>

      <Button
        href={getNextStep(!!token, fiveTwoNineAccountSlug)}
        className="btn-block btn-sm mt-4"
      >
        Contribute
      </Button>

      {!!viewer && (
        <TextWrap>
          You are signed in as
          <div>{viewer.email}</div>
          Sign in as someone else?{" "}
          <Link
            to={contributionSignInPath({
              fiveTwoNineAccountSlug,
            })}
          >
            Click Here
          </Link>
        </TextWrap>
      )}
    </div>
  )
}

const FirstNameDiv = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.lg,
  textAlign: "center",
  marginTop: theme.margins.md,
}))

export default DisplayFiveTwoNineAccount
