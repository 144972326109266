import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import React from "react"
import { LogoSolid } from "./LogoSolid"

interface LoadingScreenProps {
  message?: React.ReactNode
}

export const LoadingScreen = ({ message }: LoadingScreenProps) => {
  const theme = useTheme()
  return (
    <Container>
      <HadleyLogo />
      {message ? <MessageWrapper>{message}</MessageWrapper> : null}
    </Container>
  )
}

const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}))

const HadleyLogo = styled(LogoSolid)(({ theme }) => ({
  marginBottom: theme.margins.lg,
}))

const MessageWrapper = styled.span(({ theme }) => ({
  marginHorizontal: theme.margins.lg,
  marginBottom: theme.margins.lg,
  fontWeight: "600",
  fontSize: theme.fontSizes.lg,
  textAlign: "center",
}))
