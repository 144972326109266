import styled from "@emotion/styled"
import React, { useEffect } from "react"
import { useIdleTimer } from "react-idle-timer"
import { Outlet, useParams } from "react-router-dom"
import { ContainerDiv, ContentDiv } from "../../ui/Layout"
import ScrollToTop from "../../ui/ScrollToTop"
import { useFiveTwoNineQuery } from "../../util/useFiveTwoNineQuery"
import { useLogout } from "../../util/useLogout"
import Nav from "./Nav"

const SESSION_EXPIRATION_MINUTES = 60

const FiveTwoNineAccount = () => {
  const params = useParams()
  const { data: fiveTwoNineData, error } = useFiveTwoNineQuery(
    params.fiveTwoNineAccountSlug,
  )

  useEffect(() => {
    if (error?.message === "Five Two Nine Account not found") {
      throw new Response("Not Found", { status: 404 })
    }
  }, [error])

  const logout = useLogout()

  useIdleTimer({
    timeout: 1000 * 60 * SESSION_EXPIRATION_MINUTES,
    onIdle: () => {
      console.log(
        `Session has been idle for ${SESSION_EXPIRATION_MINUTES} minutes. Logging out...`,
      )
      logout()
      window.location.href = "/"
    },
  })

  return (
    <PageDiv>
      <ScrollToTop />
      <Nav fiveTwoNineAccountSlug={params.fiveTwoNineAccountSlug} />
      <ContainerDiv>
        <ContentDiv>
          {!!fiveTwoNineData?.fiveTwoNineAccount && (
            <Outlet
              context={{
                fiveTwoNineAccountSlug: params.fiveTwoNineAccountSlug,
                fiveTwoNineAccount: fiveTwoNineData?.fiveTwoNineAccount,
              }}
            />
          )}
        </ContentDiv>
      </ContainerDiv>
    </PageDiv>
  )
}

const PageDiv = styled.div(({ theme }) => ({}))

export default FiveTwoNineAccount
