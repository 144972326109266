import styled from "@emotion/styled"
import { Dialog } from "@headlessui/react"
import React, { useRef } from "react"

const Modal = ({
  isOpen,
  close,
  children,
}: {
  isOpen: boolean
  close: () => void
  children: React.ReactNode
}) => {
  const closeButtonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <Dialog open={isOpen} onClose={close} initialFocus={closeButtonRef}>
      <Dialog.Panel
        style={{
          position: "fixed",
          zIndex: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <BackdropButton onClick={close} />
        <ModalContentDiv>
          <CloseButton
            onClick={close}
            style={{}}
            ref={closeButtonRef}
            title="Close"
          >
            ✕
          </CloseButton>
          {children}
        </ModalContentDiv>
      </Dialog.Panel>
    </Dialog>
  )
}

const BackdropButton = styled.button(({ theme }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  background: "#00000008",
  border: 0,
  padding: 0,
}))

const ModalContentDiv = styled.div(({ theme }) => ({
  maxWidth: 600,
  borderRadius: 10,
  padding: 30,
  margin: 20,
  width: "100%",
  minHeight: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 10,
  backgroundColor: "#ffffff",
  boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
  position: "relative",
}))

const CloseButton = styled.button(({ theme }) => ({
  position: "absolute",
  top: 3,
  right: 8,
  background: "transparent",
  border: 0,
  opacity: 0.5,
}))

export default Modal
