import styled from "@emotion/styled"
import React, { useState } from "react"
import AddIcon from "./add.png"
import Modal from "./Modal"

export const InfoBlock = ({ children }: { children: React.ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <InfoBlockButton onClick={() => setIsModalOpen(true)}>
        <img src={AddIcon} style={{ width: 14, height: 14 }} alt="More info" />
      </InfoBlockButton>

      <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        {children}
      </Modal>
    </>
  )
}

const InfoBlockButton = styled.button(({ theme }) => ({
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 18,
  height: 18,
  border: 0,
  background: "transparent",
}))
