import styled from "@emotion/styled"
import React from "react"

import { Row, RowField } from "./Layout"
import LogoLg from "./logo-lg.svg"
import { H1 } from "./Typography"

const FormHeader = ({
  title,
  description,
  showLogo = true,
}: {
  title: string
  description?: string
  showLogo?: boolean
}) => {
  return (
    <ContainerDiv>
      {!!showLogo && (
        <div>
          <img src={LogoLg} alt="Hadley Logo" width={64} height={55} />
        </div>
      )}
      <Row>
        <RowField>
          <div>
            <H1>{title}</H1>
            {!!description && (
              <DescriptionDiv>
                <p>{description}</p>
              </DescriptionDiv>
            )}
          </div>
        </RowField>
      </Row>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: theme.margins.lg,
  marginTop: theme.margins.lg,
}))

const DescriptionDiv = styled.div(() => ({
  maxWidth: 240,
  margin: "0 auto",
}))

export default FormHeader
