import styled from "@emotion/styled"
import { Field, FieldAttributes, useFormikContext } from "formik"
import React from "react"
import { InputField } from "../../../ui/FormFields"

export const AmountInput = (props: FieldAttributes<any>) => {
  const { setFieldValue } = useFormikContext()
  return (
    <AmountContainerView>
      <DollarSignText>$</DollarSignText>
      <Field
        name="amount"
        placeholder="0.00"
        component={AmountTextInput}
        maxLength={12}
        hideError={true}
        {...props}
      />
    </AmountContainerView>
  )
}

const AmountTextInput = styled(InputField)(({ theme }) => ({
  height: 48,
  width: 90,
  color: theme.colors.blue,
  fontSize: theme.fontSizes.sm,
  borderColor: theme.colors.borderGray,
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: "solid",
  paddingLeft: theme.margins.sm,
  paddingRight: theme.margins.sm,
  textAlign: "right",
  outline: 0,
}))

const AmountContainerView = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  justifyContent: "flex-end",
  alignItems: "center",
  height: 75,
}))

const DollarSignText = styled.div(({ theme }) => ({
  marginRight: 4,
  color: theme.colors.blue,
  fontSize: theme.fontSizes.sm,
}))
