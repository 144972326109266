import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { GetViewerIdentityQuery } from "../generated/graphql"
import { GET_VIEWER_IDENTITY } from "../graphql/queries"

const useShouldRequireAddress = () => {
  const [shouldRequireAddress, setRequireAddress] = useState(false)
  const { data: viewerIdentity, loading: addressLoading } =
    useQuery<GetViewerIdentityQuery>(GET_VIEWER_IDENTITY)

  useEffect(() => {
    setRequireAddress(
      !(
        viewerIdentity?.viewer?.address?.streetAddress1 &&
        viewerIdentity?.viewer?.address?.city &&
        viewerIdentity?.viewer?.address?.state &&
        viewerIdentity?.viewer?.address?.zip &&
        viewerIdentity?.viewer?.billingFirstName &&
        viewerIdentity?.viewer?.billingLastName
      ),
    )
  }, [setRequireAddress, viewerIdentity])

  return {
    viewerIdentity,
    shouldRequireAddress,
    setRequireAddress,
    addressLoading,
  }
}

export default useShouldRequireAddress
