import React from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { FiveTwoNineAccount } from "../../generated/graphql"
import {
  contributionSignInPath,
  fiveTwoNineContributionPath,
} from "../../util/paths"
import CreateAccountForm from "../authentication/CreateAccountForm"

const ContributionSignUp = () => {
  const navigate = useNavigate()
  const { fiveTwoNineAccount, fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccount: FiveTwoNineAccount
    fiveTwoNineAccountSlug: string
  }>()

  const onSuccess = () =>
    navigate(
      fiveTwoNineContributionPath({
        fiveTwoNineAccountSlug,
      }),
      { replace: true },
    )

  return (
    <div>
      <CreateAccountForm
        title={`Give us some information to contribute to ${fiveTwoNineAccount.beneficiaryFirstName}'s 529 plan.`}
        onSuccess={onSuccess}
        signInPath={contributionSignInPath({
          fiveTwoNineAccountSlug,
        })}
      />
    </div>
  )
}

export default ContributionSignUp
