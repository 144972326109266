import styled from "@emotion/styled"
import React from "react"
import { useRouteError } from "react-router-dom"
import { ContentDiv } from "../../ui/Layout"
import Nav from "./Nav"

const ErrorLayout = ({
  title,
  message,
}: { title: string; message: string }) => {
  return (
    <div>
      <Nav />
      <ContentDiv>
        <ErrorMessageDiv>
          <ErrorMessageH1>{title}</ErrorMessageH1>
          <br />
          {message}
        </ErrorMessageDiv>
      </ContentDiv>
    </div>
  )
}

const FiveTwoNineAccountErrorBoundary = () => {
  const error = useRouteError() as { status?: number } | undefined
  if (error?.status === 404) {
    return (
      <ErrorLayout
        title="Oops.. the page you are looking for can't be found."
        message="Please check the url and try again."
      />
    )
  }

  return (
    <ErrorLayout
      title="Oops.. something went wrong."
      message="Please try again."
    />
  )
}

const ErrorMessageH1 = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xl,
  color: theme.colors.textPrimary,
  lineHeight: 1.2,
}))

const ErrorMessageDiv = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.md,
  maxWidth: 500,
  textAlign: "center",
  margin: "0 auto",
  marginTop: 100,
  marginBottom: 100,
  color: theme.colors.textMedium,
}))

export default FiveTwoNineAccountErrorBoundary
