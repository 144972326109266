import { useQuery } from "@apollo/client"
import { useAuthContext } from "../authentication/AuthProvider"
import { BankAccount } from "../generated/graphql"
import { GET_BANK_ACCOUNT } from "../graphql/queries"
import { useLogout } from "./useLogout"

export const useBankAccount = () => {
  const logout = useLogout()
  const { token } = useAuthContext()

  const showAccountFetchError = () => {
    alert("Error fetching bank account\nPlease refresh the page and try again")
  }

  const { data, error, loading } = useQuery<{
    bankAccount: BankAccount
  }>(GET_BANK_ACCOUNT, {
    fetchPolicy: "cache-and-network",
    onError: (e) => {
      if (e.message.includes("Session Not Found")) {
        logout()
        alert("Sorry, your session has expired")
        window.location.href = "/"
      } else if (e.message.includes("Must be logged in")) {
        alert("Please ensure you're logged in")
        window.location.href = "/"
      } else {
        showAccountFetchError()
      }
    },
    skip: !token,
  })

  return {
    bankAccount: data?.bankAccount,
    loading,
    error,
  }
}
