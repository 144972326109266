import styled from "@emotion/styled"

export const SectionHeading = styled.div(({ theme }) => ({
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderColor: theme.colors.borderGray,
  paddingVertical: theme.margins.sm,
  paddingHorizontal: theme.margins.md,
}))

export const SectionHeadingText = styled.div(({ theme }) => ({
  color: theme.colors.textMedium,
}))
