import { useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"

import React, { useCallback, useEffect, useState } from "react"
import {
  PlaidInstitution,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from "react-plaid-link"

import {
  ADD_BANK_ACCOUNT,
  GET_BANK_ACCOUNT,
  GET_PLAID_LINK_TOKEN,
  GET_VIEWER,
} from "../../graphql/queries"

import styled from "@emotion/styled"
import { logEvent } from "../../analytics/analytics"
import Button from "../../ui/Button"
import { LoadingScreen } from "../../ui/LoadingScreen"
import { useSafeMutation } from "../../util/useSafeMutation"
import { SkyflowInsertionResult } from "../../util/useSkyflow"
import {
  PLAID_LINK_TOKEN_KEY,
  PLAID_RETURN_URL_KEY,
  PLAID_SUCCESS_METADATA_KEY,
} from "../../util/plaidConstants"
import { AddAccountInfoManually } from "./AddAccountInfoManually"
import BankAccountIcon from "./bank_account_icon.svg"

type LinkSuccess = {
  publicToken: string
  metadata: PlaidLinkOnSuccessMetadata
}

const MANUAL_INSTITUTION_IDS = [
  "ins_56", // Chase
]

const institutionRequiresManualEntry = (institution: PlaidInstitution) => {
  return MANUAL_INSTITUTION_IDS.includes(institution.institution_id)
}

export const AddBankAccount = ({ onClose }: { onClose: () => void }) => {
  const { data, error: linkTokenError } = useQuery<
    { plaidLinkToken: string } | undefined
  >(GET_PLAID_LINK_TOKEN)

  const [execAddBankAccount, { loading: addBankAccountLoading }] =
    useSafeMutation(ADD_BANK_ACCOUNT)
  const [linkData, setLinkData] = useState<LinkSuccess | undefined>()
  const [showManualEntry, setShowManualEntry] = useState(false)

  const plaidLinkToken = data?.plaidLinkToken
  useEffect(() => {
    if (linkTokenError) {
      Sentry.captureException(linkTokenError)
      alert("Error initializing Plaid\nPlease refresh the page and try again")
    }
  }, [linkTokenError])

  useEffect(() => {
    if (plaidLinkToken) {
      sessionStorage.setItem(PLAID_LINK_TOKEN_KEY, plaidLinkToken)
      sessionStorage.setItem(PLAID_RETURN_URL_KEY, window.location.pathname)
    }
  }, [plaidLinkToken])

  const saveBankAccount = useCallback(
    (linkSuccess: LinkSuccess, skyflowTokens?: SkyflowInsertionResult) => {
      const account = linkSuccess.metadata.accounts[0]

      execAddBankAccount({
        variables: {
          input: {
            plaidPublicToken: linkSuccess.publicToken,
            plaidInstitutionId:
              linkSuccess.metadata.institution?.institution_id,
            plaidAccountId: account.id,
            accountType: account.subtype,
            name: account.name,
            officialName: account.name,
            accountNumberMask: account.mask,
            skyflowAccountToken: skyflowTokens?.account,
            skyflowRoutingToken: skyflowTokens?.routing,
          },
        },
        refetchQueries: [{ query: GET_BANK_ACCOUNT }, { query: GET_VIEWER }],
        onCompleted: () => {
          console.log("success!")
          logEvent("Linked Bank Account", {
            message: "User linked bank account",
          })
          onClose()
        },
        onError: (e) => {
          Sentry.captureException(e)
          alert("Error saving bank account\nPlease contact support")
        },
      })
    },
    [execAddBankAccount, onClose],
  )

  const onPlaidSuccess = (success: LinkSuccess) => {
    console.log({ success })
    setLinkData(success)
    const institution = success.metadata.institution
    if (institution && institutionRequiresManualEntry(institution)) {
      setShowManualEntry(true)
    } else {
      saveBankAccount(success)
    }
  }

  const onCancelPress = () => {
    const confirmed = confirm(
      "Exit Bank Account Entry\nAre you sure you want to stop entering this bank account and lose your progress?",
    )
    if (confirmed) {
      setShowManualEntry(false)
      setLinkData(undefined)
      onClose()
    }
  }

  useEffect(() => {
    const plaidSuccessMetadata = sessionStorage.getItem(
      PLAID_SUCCESS_METADATA_KEY,
    )
    if (plaidSuccessMetadata) {
      sessionStorage.removeItem(PLAID_SUCCESS_METADATA_KEY)
      onPlaidSuccess(JSON.parse(plaidSuccessMetadata))
    }
  }, [])

  if (addBankAccountLoading) return <LoadingScreen message="Loading..." />

  if (showManualEntry && linkData)
    return (
      <AddAccountInfoManually
        linkSuccess={linkData}
        onSave={(tokens) => {
          saveBankAccount(linkData, tokens)
        }}
        onCancel={onCancelPress}
      />
    )

  return (
    <PageContainer style={{ alignItems: "center" }}>
      <BankAccountIconContainer>
        <img src={BankAccountIcon} alt="add bank account" />
      </BankAccountIconContainer>
      <MainHeading>Add Checking Account</MainHeading>
      <DescriptionText>
        Hadley uses bank-level security standards to ensure your information
        stays protected and safe.
      </DescriptionText>
      <Spacer />
      {plaidLinkToken && (
        <AddBankAccountButton
          plaidLinkToken={plaidLinkToken}
          onPlaidSuccess={onPlaidSuccess}
        />
      )}
      {!plaidLinkToken && <p>Loading...</p>}
    </PageContainer>
  )
}

const AddBankAccountButton = ({
  plaidLinkToken,
  onPlaidSuccess,
}: {
  plaidLinkToken: string
  onPlaidSuccess: (success: LinkSuccess) => void
}) => {
  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      console.log({ publicToken, metadata })
      onPlaidSuccess({ publicToken, metadata })
    },
    onExit: (error, metadata) => {
      if (error?.error_message) {
        Sentry.setContext("plaid-error", error)
        Sentry.setContext("plaid-metadata", metadata)
        Sentry.captureException(new Error(error.error_message))
        alert("Error linking bank account\nPlease contact support")
      }
    },
  })

  return (
    <Button onClick={() => open()} disabled={!ready}>
      Add Account
    </Button>
  )
}

export default AddBankAccount

export const MainHeading = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xxl,
  fontWeight: "600",
  marginTop: theme.margins.lg,
  marginBottom: theme.margins.md,
  color: theme.colors.textPrimary,
}))

const BankAccountIconContainer = styled.div(({ theme }) => ({
  width: 100,
  height: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 100,
  backgroundColor: theme.colors.lightestGray,
  borderStyle: "solid",
  borderColor: theme.colors.borderGray,
  borderWidth: 1,
}))

const DescriptionText = styled.div(({ theme }) => ({
  textAlign: "center",
  color: theme.colors.textLight,
  maxWidth: 300,
}))

const Spacer = styled.div(({ theme }) => ({
  height: theme.margins.md,
}))

const PageContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.margins.lg,
  alignItems: "center",
}))
