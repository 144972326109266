import { UPDATE_VIEWER } from "../graphql/queries"
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "../generated/graphql"
import { useSafeMutation } from "../util/useSafeMutation"

export const useUpdateUser = () => {
  const [execUpdateUser] = useSafeMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATE_VIEWER)

  return execUpdateUser
}
