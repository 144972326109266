import React, { useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { useViewerMaybe } from "../../../authentication/ViewerProvider"

import { AccountFlagEnum, FiveTwoNineAccount } from "../../../generated/graphql"
import {
  contributionPaymentMethodPath,
  contributionSignUpPath,
} from "../../../util/paths"
import { useBankAccount } from "../../../util/useBankAccount"
import useShouldRequireAddress from "../../../util/useShouldRequireAddress"
import ContributionForm from "./ContributionForm"

const Contribution = () => {
  const navigate = useNavigate()
  const { fiveTwoNineAccount, fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccount: FiveTwoNineAccount
    fiveTwoNineAccountSlug: string
  }>()
  const { viewer, loading: viewerLoading } = useViewerMaybe()
  const { bankAccount, loading: bankLoading } = useBankAccount()
  const { shouldRequireAddress, addressLoading } = useShouldRequireAddress()

  const requireSignIn = !(viewer || viewerLoading)
  const requireAddress = shouldRequireAddress || addressLoading
  const requireBank =
    !(bankAccount || bankLoading) ||
    viewer?.accountFlags?.find(
      ({ accountFlag, value }) =>
        accountFlag === AccountFlagEnum.InvalidBankAccount && value,
    )

  useEffect(() => {
    if (requireSignIn) {
      navigate(
        contributionSignUpPath({
          fiveTwoNineAccountSlug,
        }),
        { replace: true },
      )
    } else if (requireBank || requireAddress) {
      navigate(
        contributionPaymentMethodPath({
          fiveTwoNineAccountSlug,
        }),
        { replace: true },
      )
    }
  }, [requireBank, requireAddress, requireSignIn])

  if (!(viewer && fiveTwoNineAccount)) return null

  return (
    <>
      <ContributionForm fiveTwoNineAccount={fiveTwoNineAccount} />
    </>
  )
}

export default Contribution
