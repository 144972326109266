import styled from "@emotion/styled"
import React from "react"
import { Maybe } from "../generated/graphql"
import GiftImg from "./gift.svg"
import { theme } from "./theme"

interface AvatarProps {
  imageSrc?: Maybe<string>
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  isLoading?: Maybe<boolean>
  diameter?: number
  fontSize?: number
  padding?: number
  backgroundColor?: string
  color?: string
  borderColor?: string
  borderWidth?: number
  showGift?: boolean
}

export const Avatar: React.FC<AvatarProps> = ({
  imageSrc,
  firstName,
  lastName,
  diameter = 50,
  fontSize = 15,
  isLoading = false,
  backgroundColor = "#EEEEEF",
  color = theme.colors.textPrimary,
  borderColor = theme.colors.white,
  borderWidth,
  showGift,
}) => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: diameter,
    height: diameter,
    borderRadius: diameter,
    backgroundColor,
    borderStyle: "solid",
    borderColor,
    borderWidth,
    overflow: "hidden",
  }

  if (isLoading) {
    return <AvatarContainer style={style} />
  }

  if (showGift) {
    return (
      <AvatarContainer>
        <div
          style={{
            ...style,
            background: theme.colors.primary,
          }}
        >
          <img src={GiftImg} alt={firstName || ""} width={27} height={27} />
        </div>
      </AvatarContainer>
    )
  }

  if (imageSrc)
    return (
      <AvatarContainer>
        <div style={style}>
          <img src={imageSrc} width="100%" alt={firstName || ""} />
        </div>
      </AvatarContainer>
    )

  if (firstName)
    return (
      <AvatarContainer style={style}>
        <AvatarText style={{ fontSize, color }}>
          {firstName[0]}
          {lastName?.[0]}
        </AvatarText>
      </AvatarContainer>
    )

  return null
}

const AvatarContainer = styled.div(({ theme }) => ({
  borderRadius: 1000,
  alignItems: "center",
  justifyContent: "center",
}))

const AvatarText = styled.div(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 24,
  fontWeight: 600,
  color: theme.colors.textPrimary,
}))
