import React from "react"
import { FiveTwoNineAccount, Maybe } from "../generated/graphql"
import { Avatar } from "./Avatar"
import { theme } from "./theme"

export const FiveTwoNineAvatar = ({
  firstName,
  lastName,
  photoUrl,
  beneficiaryFirstName,
  beneficiaryPhotoUrl,
  userDiameter = 50,
  beneficiaryDiameter = 24,
  userFontSize,
  beneficiaryFontSize = 10,
  borderWidth = 2,
  showGift,
}: {
  firstName?: Maybe<string>
  lastName?: string
  photoUrl?: Maybe<string>
  beneficiaryFirstName?: Maybe<string>
  beneficiaryPhotoUrl?: Maybe<string>
  userDiameter?: number
  beneficiaryDiameter?: number
  userFontSize?: number
  beneficiaryFontSize?: number
  borderColor?: Maybe<string>
  borderWidth?: number
  showGift?: boolean
}) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {!!firstName && (
        <Avatar
          imageSrc={photoUrl}
          firstName={firstName}
          lastName={lastName ?? ""}
          diameter={userDiameter}
          fontSize={userFontSize}
        />
      )}
      <div
        style={{
          width: beneficiaryDiameter,
          height: beneficiaryDiameter,
          borderColor: theme.colors.white,
          borderRadius: 24,
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      >
        {!!beneficiaryFirstName && (
          <Avatar
            imageSrc={beneficiaryPhotoUrl}
            firstName={beneficiaryFirstName}
            diameter={beneficiaryDiameter}
            fontSize={beneficiaryFontSize}
            borderWidth={borderWidth}
            padding={2}
            backgroundColor={theme.colors.primary}
            color={theme.colors.white}
            showGift={showGift}
          />
        )}
      </div>
    </div>
  )
}

export const LargeFiveTwoNineAvatar = ({
  fiveTwoNineAccount,
  showGift,
}: { fiveTwoNineAccount: FiveTwoNineAccount; showGift?: boolean }) => {
  const props = showGift
    ? {
        firstName: fiveTwoNineAccount.beneficiaryFirstName,
        photoUrl: fiveTwoNineAccount.beneficiaryPhotoUrl,
        showGift: true,
      }
    : {
        firstName: fiveTwoNineAccount.user?.firstName,
        photoUrl: fiveTwoNineAccount.user?.photoUrl,
      }
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FiveTwoNineAvatar
        userDiameter={130}
        beneficiaryDiameter={65}
        userFontSize={56}
        beneficiaryFontSize={14}
        beneficiaryFirstName={fiveTwoNineAccount.beneficiaryFirstName}
        beneficiaryPhotoUrl={fiveTwoNineAccount.beneficiaryPhotoUrl}
        borderWidth={2}
        {...props}
      />
    </div>
  )
}
