import styled from "@emotion/styled"
import React from "react"
import { FiveTwoNineAccount } from "../../../generated/graphql"
import { FiveTwoNineAvatar } from "../../../ui/FiveTwoNineAvatar"
import { userName } from "../../../util/users"

export const RecipientListItem = ({
  fiveTwoNineAccount,
}: {
  fiveTwoNineAccount: FiveTwoNineAccount
}) => {
  const { user } = fiveTwoNineAccount
  if (user == null) throw Error("Missing user")

  return (
    <ContainerView>
      <FiveTwoNineAvatar
        firstName={user.firstName}
        photoUrl={user.photoUrl}
        beneficiaryFirstName={fiveTwoNineAccount.beneficiaryFirstName}
        beneficiaryPhotoUrl={fiveTwoNineAccount.beneficiaryPhotoUrl}
      />

      <UserDetailsView>
        <UserNameText>{userName(user)}</UserNameText>
        {!!user.email && <UserEmailText>{user.email}</UserEmailText>}
        <UserEmailText>{fiveTwoNineAccount.beneficiaryFirstName}</UserEmailText>
      </UserDetailsView>
    </ContainerView>
  )
}

const ContainerView = styled.div(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  paddingTop: theme.margins.sm,
  paddingBottom: theme.margins.sm,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}))

const UserNameText = styled.div(({ theme }) => ({
  fontWeight: "600",
  fontSize: theme.fontSizes.md,
  color: theme.colors.textPrimary,
}))

const UserEmailText = styled.div(({ theme }) => ({
  flex: 1,
  fontSize: theme.fontSizes.sm,
  color: theme.colors.textLight,
}))

const UserDetailsView = styled.div(({ theme }) => ({
  flex: 1,
  paddingLeft: theme.margins.sm,
}))
