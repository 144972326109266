import styled from "@emotion/styled"

export const Card = styled.div(({ theme }) => ({
  borderRadius: theme.margins.xs,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.colors.borderGray,
  backgroundColor: theme.colors.white,
  boxShadow: "0px 8px 14px rgba(0, 0, 0, 0.06);",
  shadowRadius: 10,
}))

export const CardBody = styled.div(({ theme }) => ({
  padding: theme.margins.lg,
}))

export const CardListItem = styled.div(({ theme }) => ({
  paddingTop: theme.margins.xs,
  paddingBottom: theme.margins.xs,
  paddingLeft: theme.margins.md,
  paddingRight: theme.margins.md,
  borderWidth: 0,
  borderTopWidth: 1,
  borderColor: theme.colors.borderGray,
  borderStyle: "solid",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}))

export const CardListItemLabel = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  color: theme.colors.textLight,
  marginRight: theme.margins.xs,
}))

export const CardListItemValue = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  color: theme.colors.textPrimary,
  marginLeft: "auto",
}))
