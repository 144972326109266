import styled from "@emotion/styled"
import React from "react"

export const TitledParagrah = ({
  title,
  paragraph,
  children,
}: {
  title?: string
  paragraph?: string
  children?: React.ReactNode
}) => {
  return (
    <ContainerView>
      <View>
        {!!title && <Title>{title}</Title>}
        {!!paragraph && <Paragraph>{paragraph}</Paragraph>}
      </View>
      {children}
    </ContainerView>
  )
}

export const ContainerView = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.margins.sm,
}))

export const Title = styled.h1(({ theme }) => ({
  color: theme.colors.textPrimary,
  fontSize: theme.fontSizes.lg,
  fontWeight: "600",
  marginBottom: theme.margins.sm,
}))

export const Paragraph = styled.p(({ theme }) => ({
  color: theme.colors.textLight,
  fontSize: theme.fontSizes.sm,
  marginBottom: 0,
}))

const View = styled.div(() => ({}))
