import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "previousLink",
    "currentStep",
    "stepContent",
    "requiredText",
    "timeUntilSchoolInput",
    "childAgeInput",
    "stepText",
  ]

  connect() {
    this.currentStep = 1
    this.requiredTextTargets.forEach((text) => (text.style.display = "none"))
    this.goToStep()
  }

  nextStep(event) {
    event?.preventDefault()
    const numSteps = this.stepContentTargets.length

    if (this.currentStep >= numSteps) {
      this.submitQuestionnaire()
    } else {
      this.currentStep++
      this.goToStep()
    }
  }

  prevStep(event) {
    event?.preventDefault()
    this.currentStep--
    this.goToStep()
  }

  goToStep() {
    if (this.hasCurrentStepTarget) {
      this.currentStepTarget.textContent = `Step ${this.currentStep}`
    }
    this.togglePreviousLink(this.currentStep)

    this.stepContentTargets.forEach((element, index) => {
      const isStep = this.currentStep === index + 1
      const display = isStep ? "block" : "none"
      element.style.display = display

      if (isStep) {
        this.stepTextTarget.innerText = element.dataset.stepText || ""
      }
    })

    window.scrollTo(0, 0)
  }

  togglePreviousLink(step) {
    const display = step > 1 ? "flex" : "none"
    this.previousLinkTarget.style.display = display
  }

  validate(event) {
    event.preventDefault()
    const stepContent = this.stepContentTargets.find((target) =>
      target.contains(event.target),
    )

    let valid = true
    stepContent.querySelectorAll("input, select").forEach((control) => {
      if (!control.validity.valid) {
        control.classList.add("is-invalid")
        valid = false
      } else {
        control.classList.remove("is-invalid")
      }
    })

    if (valid) {
      this.nextStep()
    }
  }

  validateSavingFor(event) {
    event.preventDefault()

    const stepContent = this.stepContentTargets.find((target) =>
      target.contains(event.target),
    )
    const requiredText = this.requiredTextTargets.find((text) =>
      stepContent.contains(text),
    )

    const selectedValue = stepContent.querySelector(
      "input[type='radio']:checked",
    )?.value

    let valid = true
    if (selectedValue?.length > 0) {
      requiredText.style.display = "none"
    } else {
      valid = false
      requiredText.style.display = "block"
    }

    this.timeUntilSchoolInputTarget.classList.remove("is-invalid")
    this.childAgeInputTarget.classList.remove("is-invalid")
    switch (selectedValue) {
      case "myself":
        const timeUntilSchool = this.timeUntilSchoolInputTarget.value
        if (timeUntilSchool.length <= 0 || timeUntilSchool < 0) {
          valid = false
          this.timeUntilSchoolInputTarget.classList.add("is-invalid")
        }
        break
      case "existing_child":
        const childAge = this.childAgeInputTarget.value
        console.log("childAge", childAge)
        if (childAge.length <= 0 || childAge < 0) {
          valid = false
          this.childAgeInputTarget.classList.add("is-invalid")
        }
        break
    }

    if (valid) {
      this.nextStep()
    }
  }

  validateRadio(event) {
    event.preventDefault()

    const stepContent = this.stepContentTargets.find((target) =>
      target.contains(event.target),
    )
    const requiredText = this.requiredTextTargets.find((text) =>
      stepContent.contains(text),
    )

    const selectedValue = stepContent.querySelector(
      "input[type='radio']:checked",
    )?.value

    let valid = true
    if (selectedValue?.length > 0) {
      requiredText.style.display = "none"
    } else {
      valid = false
      requiredText.style.display = "block"
    }

    if (valid) {
      this.nextStep()
    }
  }

  validateInvestmentApproach(event) {
    event.preventDefault()

    const stepContent = this.stepContentTargets.find((target) =>
      target.contains(event.target),
    )
    const requiredText = this.requiredTextTargets.find((text) =>
      stepContent.contains(text),
    )

    const selectedValue = stepContent.querySelector(
      "input[type='radio']:checked",
    )?.value

    let valid = true
    if (selectedValue?.length > 0) {
      requiredText.style.display = "none"
    } else {
      valid = false
      requiredText.style.display = "block"
    }

    if (valid) {
      switch (selectedValue) {
        case "not_sure":
          this.nextStep()
          break
        default:
          this.submitQuestionnaire()
      }
    }
  }

  submitQuestionnaire(event) {
    // const data = new FormData(this.element);
    // console.log("submitting", data)
    this.element.submit()
  }
}
