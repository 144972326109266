import styled from "@emotion/styled"
import React from "react"
import ReactConfetti from "react-confetti"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { useAuthContext } from "../../authentication/AuthProvider"
import { FiveTwoNineAccount } from "../../generated/graphql"
import Button from "../../ui/Button"
import { LargeFiveTwoNineAvatar } from "../../ui/FiveTwoNineAvatar"
import { theme } from "../../ui/theme"
import { TextSemiBold } from "../../ui/Typography"
import { centsToDollars } from "../../util/currency"
import { contributionSignInPath } from "../../util/paths"
import { useLogout } from "../../util/useLogout"
import AppleStoreImg from "./applestore.png"
import AppleStoreImg2X from "./applestore@2x.png"
import GooglePlayImg from "./googleplay.png"
import GooglePlayImg2X from "./googleplay@2x.png"

const APPLE_APP_URL =
  "https://apps.apple.com/us/app/hadley-your-529-plan-app/id1633464445"
const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.hadley.release"

const ContributionSuccess = () => {
  const { fiveTwoNineAccount, fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccount: FiveTwoNineAccount
    fiveTwoNineAccountSlug: string
  }>()
  const navigate = useNavigate()
  const { token } = useAuthContext()
  const logOut = useLogout()
  const location = useLocation()
  const amountCents = new URLSearchParams(location.search).get("amountCents")

  const onLogOutClick = () => {
    logOut()
    navigate(
      contributionSignInPath({
        fiveTwoNineAccountSlug,
      }),
    )
  }

  return (
    <ContainerView>
      <ReactConfetti
        numberOfPieces={700}
        recycle={false}
        style={{ position: "fixed", left: 0, top: 0 }}
      />
      <AvatarContainerView>
        <LargeFiveTwoNineAvatar
          fiveTwoNineAccount={fiveTwoNineAccount}
          showGift={true}
        />
      </AvatarContainerView>
      <SuccessText>
        You just contributed{" "}
        {!!amountCents && (
          <TextSemiBold>{centsToDollars(Number(amountCents))}</TextSemiBold>
        )}{" "}
        to{" "}
        <TextSemiBold>{fiveTwoNineAccount.beneficiaryFirstName}'s</TextSemiBold>{" "}
        education!
      </SuccessText>

      <SuccessSubTextDiv>
        Make it even easier next time and download our free app to:
      </SuccessSubTextDiv>

      <DownloadReasonsUl>
        <DownloadReasonLi>
          Make a recurring gift to {fiveTwoNineAccount.beneficiaryFirstName}
        </DownloadReasonLi>
        <DownloadReasonLi>Make a gift to someone else</DownloadReasonLi>
        <DownloadReasonLi>
          Make it super easy for others to send money to your education savings
          (Hadley works with all 529 plans – link yours today!)
        </DownloadReasonLi>
      </DownloadReasonsUl>

      <DownloadTextDiv>
        <div>Download the Hadley app today.</div>
      </DownloadTextDiv>

      <DownloadButtonsDiv>
        <a href={APPLE_APP_URL}>
          <img
            src={AppleStoreImg}
            srcSet={`${AppleStoreImg2X} 2x`}
            alt="Download on App Store"
          />
        </a>
        <a href={GOOGLE_PLAY_URL}>
          <img
            src={GooglePlayImg}
            srcSet={`${GooglePlayImg2X} 2x`}
            alt="Download on Google Play"
          />
        </a>
      </DownloadButtonsDiv>

      <SpreadTheWordDiv>
        <b>Spread the word:</b> tell your friends and family about Hadley. The
        sky's the limit when we help each other save for it.
      </SpreadTheWordDiv>

      {!!token && (
        <SignOutDiv>
          <Button buttonStyle="link" className="btn-sm" onClick={onLogOutClick}>
            Sign Out
          </Button>
        </SignOutDiv>
      )}
    </ContainerView>
  )
}

const ContainerView = styled.div(({ theme }) => ({
  position: "relative",
}))

const AvatarContainerView = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.margins.xxs,
  marginBottom: theme.margins.md,
  paddingLeft: theme.margins.md,
  paddingRight: theme.margins.md,
}))

const SuccessText = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xl,
  color: theme.colors.textPrimary,
  textAlign: "center",
  marginTop: theme.margins.lg,
  marginBottom: theme.margins.md,
  paddingLeft: theme.margins.md,
  paddingRight: theme.margins.md,
}))

const SuccessSubTextDiv = styled.div(() => ({
  maxWidth: 240,
  textAlign: "center",
  margin: "0 auto",
  marginBottom: theme.margins.md,
}))

const DownloadTextDiv = styled.div(({ theme }) => ({
  marginTop: theme.margins.xl,
  fontSize: theme.fontSizes.lg,
  fontWeight: 600,
  textAlign: "center",
}))

const DownloadButtonsDiv = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.margins.sm,
  margin: "0 auto",
  marginTop: theme.margins.lg,
  marginBottom: theme.margins.lg,
}))

const SignOutDiv = styled.div(({ theme }) => ({
  marginTop: theme.margins.md,
  textAlign: "center",
  fontSize: theme.fontSizes.sm,
}))

const DownloadReasonsUl = styled.ul(({ theme }) => ({
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.colors.borderGray,
  borderRadius: 8,
  paddingTop: theme.margins.xs,
  paddingBottom: theme.margins.xs,
  paddingLeft: theme.margins.sm,
  paddingRight: theme.margins.sm,
  marginLeft: -theme.margins.sm,
  marginRight: -theme.margins.sm,
}))

const DownloadReasonLi = styled.li(({ theme }) => ({
  listStyleType: "none",
  position: "relative",
  color: theme.colors.textPrimary,
  fontSize: theme.fontSizes.xs,
  paddingLeft: theme.margins.sm,
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.borderGray,
  paddingTop: theme.margins.xxs,
  paddingBottom: theme.margins.xxs,
  "&:last-child": {
    border: 0,
  },
  "&::before": {
    left: 0,
    content: '"•"',
    color: theme.colors.primary,
    fontSize: theme.fontSizes.lg,
    position: "absolute",
  },
}))

const SpreadTheWordDiv = styled.div(() => ({
  maxWidth: 300,
  textAlign: "center",
  margin: "0 auto",
}))

export default ContributionSuccess
