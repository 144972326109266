import styled from "@emotion/styled"
import React from "react"
import { Link } from "react-router-dom"
import { LogoSolid } from "../../ui/LogoSolid"
import { fiveTwoNineAccountPath } from "../../util/paths"

const Nav = ({
  fiveTwoNineAccountSlug,
}: { fiveTwoNineAccountSlug?: string }) => {
  return (
    <NavDiv>
      {fiveTwoNineAccountSlug ? (
        <Link
          to={fiveTwoNineAccountPath({
            fiveTwoNineAccountSlug,
          })}
        >
          <LogoSolid />
        </Link>
      ) : (
        <LogoSolid />
      )}
    </NavDiv>
  )
}

const NavDiv = styled.div(({ theme }) => ({
  marginBottom: theme.margins.xl,
  textAlign: "center",
  paddingTop: theme.margins.sm,
  paddingBottom: theme.margins.sm,
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.borderGray,
}))

export default Nav
