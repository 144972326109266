import React, { useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { fiveTwoNineAccountPath } from "../../util/paths"
import ForgotPasswordCodeForm from "../authentication/ForgotPasswordCodeForm"
import ForgotPasswordForm from "../authentication/ForgotPasswordForm"
import UpdatePasswordForm from "../authentication/UpdatePasswordForm"

const ContributionForgotPassword = () => {
  const navigate = useNavigate()
  const { fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccountSlug: string
  }>()

  const [code, setCode] = useState("")
  const [email, setEmail] = useState("")

  const onUpdatePasswordSuccess = () =>
    navigate(
      fiveTwoNineAccountPath({
        fiveTwoNineAccountSlug,
      }),
    )

  const onEnterEmailSuccess = (email: string) => setEmail(email)
  const onEnterCodeSuccess = (code: string) => setCode(code)

  if (!email) return <ForgotPasswordForm onSuccess={onEnterEmailSuccess} />

  return (
    <div>
      {code ? (
        <UpdatePasswordForm
          email={email}
          code={code}
          onSuccess={onUpdatePasswordSuccess}
        />
      ) : (
        <ForgotPasswordCodeForm onSuccess={onEnterCodeSuccess} />
      )}
    </div>
  )
}

export default ContributionForgotPassword
