import Skyflow from "skyflow-js"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "skyflowIdField",
    "skyflowAccountTokenField",
    "skyflowAccountInput",
    "skyflowRoutingTokenField",
    "skyflowRoutingInput",
  ]
  static values = {
    skyflowAccessTokenUrl: String,
  }

  connect() {
    this.skyflowTokensChanged = false
    this.initSkyflowContainer()
    this.mountAccountNumber()
    this.mountRoutingNumber()
  }

  getMeta(key) {
    return document.querySelector(`meta[name="${key}"]`).content
  }

  initSkyflowContainer() {
    const skyflowClient = Skyflow.init({
      vaultID: this.getMeta("skyflow_vault_id"),
      vaultURL: this.getMeta("skyflow_vault_url"),
      getBearerToken: this.getBearerToken.bind(this),
      options: {
        logLevel: Skyflow.LogLevel.ERROR,
        env: Skyflow.Env[this.getMeta("skyflow_env")],
      },
    })
    this.skyflowContainer = skyflowClient.container(
      Skyflow.ContainerType.COLLECT,
    )
  }

  async getBearerToken() {
    return await fetch(this.skyflowAccessTokenUrlValue)
      .then((resp) => resp.json())
      .then((data) => {
        return data.access_token
      })
  }

  mountAccountNumber() {
    const collectElement = {
      table: "numbers_ach",
      column: "account",
      type: Skyflow.ElementType.INPUT_FIELD,
      ...this.skyflowId(),
      ...this.styles(),
      placeholder: "Account Number *",
      validations: [
        {
          type: Skyflow.ValidationRuleType.REGEX_MATCH_RULE,
          params: {
            regex: /^[0-9]+$/,
            error: "Account number must be a number",
          },
        },
        {
          type: Skyflow.ValidationRuleType.LENGTH_MATCH_RULE,
          params: {
            min: 7,
            error: "Account number must be at least 7 digits long",
          },
        },
      ],
    }

    const options = {
      required: true,
    }

    const element = this.skyflowContainer.create(collectElement, options)
    element.on(
      Skyflow.EventName.CHANGE,
      this.handleSkyflowTokensChanged.bind(this),
    )
    element.mount(this.skyflowAccountInputTarget)
  }

  mountRoutingNumber() {
    const collectElement = {
      table: "numbers_ach",
      column: "routing",
      type: Skyflow.ElementType.INPUT_FIELD,
      ...this.skyflowId(),
      ...this.styles(),
      placeholder: "Routing Number *",
      validations: [
        {
          type: Skyflow.ValidationRuleType.REGEX_MATCH_RULE,
          params: {
            regex: /^[0-9]+$/,
            error: "Routing number must be a number",
          },
        },
        {
          type: Skyflow.ValidationRuleType.LENGTH_MATCH_RULE,
          params: {
            min: 9,
            error: "Routing number must be at least 9 digits long",
          },
        },
      ],
    }

    const options = {
      required: true,
    }

    const element = this.skyflowContainer.create(collectElement, options)
    element.on(
      Skyflow.EventName.CHANGE,
      this.handleSkyflowTokensChanged.bind(this),
    )
    element.mount(this.skyflowRoutingInputTarget)
  }

  handleSkyflowTokensChanged() {
    this.skyflowTokensChanged = true
  }

  async collectSkyflowAndSubmit(event) {
    if (this.skyflowTokensChanged) {
      event.preventDefault()
      const response = await this.skyflowContainer.collect({ tokens: true })
      const fields = response.records[0].fields
      this.skyflowIdFieldTarget.value = fields.skyflow_id
      this.skyflowAccountTokenFieldTarget.value = fields.account
      this.skyflowRoutingTokenFieldTarget.value = fields.routing
      this.element.submit()
      this.skyflowTokensChanged = false
    }
  }

  skyflowId() {
    if (this.skyflowIdFieldTarget.value.length > 0) {
      return { skyflowID: this.skyflowIdFieldTarget.value }
    } else {
      return {}
    }
  }

  styles() {
    return {
      inputStyles: {
        base: {
          fontFamily: "Arial",
          border: "1px solid #ced4da",
          borderRadius: "4px",
          fontSize: "1rem",
          padding: "0.375rem 0.75rem",
          lineHeight: "2",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
          height: "auto",
        },
        complete: {},
        empty: {},
        focus: {},
        invalid: {
          color: "#f44336",
        },
        cardIcon: {
          position: "absolute",
          left: "8px",
          bottom: "calc(50% - 12px)",
        },
      },
      labelStyles: {
        base: {
          fontSize: "16px",
          fontWeight: "bold",
          fontFamily: "Arial",
        },
      },
      errorTextStyles: {
        base: {
          fontFamily: "Arial",
          color: "red",
        },
      },
    }
  }
}
