import { FieldAttributes } from "formik"
import React from "react"
import Select from "react-select"

import { TextError } from "./TextError"

export type SelectOption = { label: string; value: string }

export const InputField = (props: FieldAttributes<any>) => {
  const {
    label,
    field: { name, onBlur, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    textarea,
    ...inputProps
  } = props
  const hasError = errors[name] && touched[name]

  const mergedInputProps = {
    id: name,
    value: value,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, e.target.value)
    },
    onBlur: () => {
      setFieldTouched(name)
      onBlur(name)
    },
    className: "form-control",
    ...inputProps,
  }

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} style={{ fontWeight: "bold" }}>
          {label}
        </label>
      )}

      {textarea ? (
        <textarea {...mergedInputProps} />
      ) : (
        <input {...mergedInputProps} />
      )}

      {!props.hideError && hasError && (
        <div>
          <TextError>{errors[name]}</TextError>
        </div>
      )}
    </div>
  )
}

export const SelectField = (props: FieldAttributes<any>) => {
  const {
    label,
    selectItems,
    placeholder,
    field: { name, onBlur, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    isLoading,
    ...selectProps
  } = props
  const hasError = errors[name] && touched[name]

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} style={{ fontWeight: "bold" }}>
          {label}
        </label>
      )}
      <Select
        value={value || props.defaultValue}
        onChange={(newValue) => setFieldValue(name, newValue)}
        onBlur={() => {
          setFieldTouched(name)
          onBlur(name)
        }}
        isDisabled={props.disabled}
        isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        name={name}
        options={props.selectItems}
        placeholder={placeholder}
        theme={(selectTheme) => ({
          ...selectTheme,
          borderRadius: 0,
          colors: {
            ...selectTheme.colors,
            neutral20: "#ced4da",
            primary: "#0064ca",
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            height: 46,
            minHeight: 46,
            borderRadius: 4,
          }),
        }}
        {...selectProps}
      />

      {!props.hideError && hasError && (
        <div>
          <TextError>{errors[name]}</TextError>
        </div>
      )}
    </div>
  )
}
